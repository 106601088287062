import { Box, Button, Flex, Text } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"

import { magazineCardAnimation } from "../../../../utils/animations/MagazineCard/magazineCardAnimation"
import { AddIcon } from "@chakra-ui/icons"
import SettingsDotsIcon from "../../../../assets/icons/permissions/SettingsDotsIcon"
import { memo } from "react"

const MagazineCard = ({ magazine }) => {
	const navigate = useNavigate()
	const location = useLocation()

	const handleButton = (e) => {
		e.stopPropagation()
		console.log(2)
	}

	const handleNavigateToWarehouse = (id) => {
		const splittedPath = location.pathname.split("/")
		navigate(`/${splittedPath[1]}/${splittedPath[2]}/${id}`)
	}

	return (
		<Box
			w={["280px", "300px", "320px", "330px", "340px"]}
			h={["170px", "180px", "190px", "200px", "220px"]}
			position='relative'
			overflow='visible'
			borderRadius='4px'
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			transition={"transform 0.3s"}
			cursor={"pointer"}
			_hover={{
				transform: "scale(1.05)",
			}}
			onClick={() => handleNavigateToWarehouse(magazine.id)}
		>
			<Flex
				position='absolute'
				width='100%'
				height='100%'
				justifyContent='center'
				alignItems='center'
				bgColor={"#FFF"}
				borderRadius='4px'
				overflow='hidden'
				backfacevisibility='hidden'
				_hover={{
					_before: {
						position: "absolute",
						content: '""',
						display: "block",
						width: "180px",
						height: "200%",
						background:
							"linear-gradient(90deg, transparent, #FFA436, #FFA436, #FFA436, #FFA436, transparent)",
						animation: `${magazineCardAnimation} 7000ms infinite linear`,
					},
				}}
			>
				<Box
					position='absolute'
					w={["277px", "297px", "317px", "327px", "337px"]}
					h={["167px", "177px", "187px", "197px", "217px"]}
					bgColor={"#FFF"}
					borderRadius='4px'
					color='#319795'
					fontWeight={600}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
				>
					<Text fontSize={[13, 14, 15, 16]}>{magazine.name}</Text>
				</Box>
				<Flex alignItems={"flex-end"} h='100%' mb={10} gap={2}>
					<Button
						p={0}
						minW='25px'
						boxSize={["25px", "30px", "35px", "40px"]}
						bgColor={"#E5E5DF"}
						_hover={{ bgColor: "#F0F0EC" }}
						onClick={handleButton}
					>
						<AddIcon boxSize={[3, 4]} />
					</Button>
					<Button
						p={0}
						minW='25px'
						boxSize={["25px", "30px", "35px", "40px"]}
						bgColor={"#E5E5DF"}
						_hover={{ bgColor: "#F0F0EC" }}
						onClick={handleButton}
					>
						<AddIcon boxSize={[3, 4]} />
					</Button>
					<Button
						p={0}
						minW='25px'
						boxSize={["25px", "30px", "35px", "40px"]}
						bgColor={"#E5E5DF"}
						_hover={{ bgColor: "#F0F0EC" }}
						onClick={handleButton}
					>
						<SettingsDotsIcon boxSize={[4, 5]} />
					</Button>
					<Button
						p={0}
						minW='25px'
						boxSize={["25px", "30px", "35px", "40px"]}
						bgColor={"#E5E5DF"}
						_hover={{ bgColor: "#F0F0EC" }}
						onClick={handleButton}
					>
						<SettingsDotsIcon boxSize={[4, 5]} />
					</Button>
					<Button
						p={0}
						minW='25px'
						boxSize={["25px", "30px", "35px", "40px"]}
						bgColor={"#E5E5DF"}
						_hover={{ bgColor: "#F0F0EC" }}
						onClick={handleButton}
					>
						<SettingsDotsIcon boxSize={[4, 5]} />
					</Button>
				</Flex>
			</Flex>
		</Box>
	)
}

export default memo(MagazineCard)
