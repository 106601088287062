import { motion } from "framer-motion"
import {
	Box,
	Button,
	Collapse,
	Divider,
	Flex,
	Image,
	List,
	ListItem,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useDisclosure,
	useMediaQuery,
} from "@chakra-ui/react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { memo, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import i18next from "i18next"

import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons"
import ExampleIcon from "../../assets/icons/header/ExampleIcon"
import IoPower from "../../assets/icons/header/IoPower"
import { logout } from "../../actions/auth/auth/auth-actions"
import OutlineHome from "../../assets/icons/header/OutlineHome"

import {
	firstLineAnimationNormal,
	firstLineAnimationReverse,
	secondLineAnimationNormal,
	secondLineAnimationReverse,
	thirdLineAnimationNormal,
	thirdLineAnimationReverse,
	chevronAnimationUp,
	getChevronAnimationDown,
} from "../../utils/animations/Header/headerAnimations"
import { changeLang } from "../../actions/lang/lang-actions"
import { setIsOpenModule, setIsOpenModuleAtStart } from "../../store/auth/auth-slice"
import AppPermissionsDesktop from "../../components/Permissions/AppPermissions/AppPermissionsDesktop/AppPermissionsDesktop"
import AppPermissionsMobile from "../../components/Permissions/AppPermissions/AppPermissionsMobile/AppPermissionsMobile"
import { usePwa } from "../../hooks/usePwa"
import { getDateAndTime } from "../../actions/system/system-actions"

const Header = () => {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.auth.user)
	const modules = useSelector((state) => state.auth.modules)
	const dateTime = useSelector((state) => state.system.dateTime)
	const pwa = usePwa()
	const [t] = useTranslation("global")
	const location = useLocation()
	const navigate = useNavigate()
	const { getDisclosureProps, isOpen, onToggle } = useDisclosure()

	const [isOpenLangConfig, setIsOpenLangConfig] = useState(false)

	const [isLargerThanMobile] = useMediaQuery("(min-width: 992px)", { ssr: false })

	const startingHeight = isLargerThanMobile ? "100%" : "0"
	const polandFlagSrc = require("../../assets/icons/header/PolandFlag.webp")
	const englandFlagSrc = require("../../assets/icons/header/EnglandFlag.webp")
	const netherlandFlagSrc = require("../../assets/icons/header/NetherlandFlag.webp")

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(getDateAndTime())
		}, 30000)

		return () => clearInterval(interval)
	}, [])

	const handleLogout = useCallback(() => {
		dispatch(logout())
	}, [dispatch])

	const handleConfigLang = useCallback(() => {
		setIsOpenLangConfig(!isOpenLangConfig)
	}, [isOpenLangConfig])

	const getLangImage = useCallback(() => {
		switch (i18next.language) {
			case "pl":
				return polandFlagSrc
			case "en":
				return englandFlagSrc
			case "nl":
				return netherlandFlagSrc
			default:
				return englandFlagSrc
		}
	}, [englandFlagSrc, netherlandFlagSrc, polandFlagSrc])

	const getConfigLangs = useCallback(() => {
		let isCorrectLang = false
		if (localStorage.getItem("language")) {
			i18next.store.options.store.forEach(() => {
				switch (localStorage.getItem("language")) {
					case "pl":
						isCorrectLang = true
						break
					case "en":
						isCorrectLang = true
						break
					case "nl":
						isCorrectLang = true
						break
					default:
						break
				}
			})
		}
		return isCorrectLang
			? i18next.store.options.store.filter((lang) => lang !== i18next.language)
			: i18next.store.options.store.filter((lang) => lang !== "en")
	}, [])

	const getConfigFlag = useCallback(
		(lang) => {
			switch (lang) {
				case "pl":
					return polandFlagSrc
				case "en":
					return englandFlagSrc
				case "nl":
					return netherlandFlagSrc
				default:
					return undefined
			}
		},
		[englandFlagSrc, netherlandFlagSrc, polandFlagSrc],
	)

	const handleChangeLang = useCallback(
		(event) => {
			dispatch(changeLang(event.target.name))
		},
		[dispatch],
	)

	const configLang = getConfigLangs().map((lang, index) => (
		<Button
			key={index}
			aria-label='Configuration language'
			display={["inline-flex", "inline-flex", "inline-flex", "flex"]}
			h='30px'
			bgColor='#E5E5DF'
			minW='30px'
			p={0}
			me={[2, 2, 2, 0]}
			mt={[0, 0, 0, 3]}
			borderRadius='50%'
			onClick={handleChangeLang}
		>
			<Image
				h={["30px", "30px", "30px", "32px"]}
				minW={["30px", "30px", "30px", "32px"]}
				name={lang}
				src={getConfigFlag(lang)}
				alt='Flag with configuration language'
			/>
		</Button>
	))

	const handleDownloadPwa = () => {
		if (!pwa) return
		pwa.prompt()
	}

	const getNavigationIcon = useCallback((iconName, isFunctionalitie) => {
		const boxSize = isFunctionalitie ? [5, 5, 5, 5, 5] : [7, 7, 7]
		if (!iconName) return <ExampleIcon boxSize={boxSize} />
		const Image = require(`../../assets/icons/header/${iconName}`).default
		return <Image boxSize={boxSize} />
	}, [])

	const toogleIsOpenModule = useCallback(() => {
		dispatch(
			setIsOpenModule({
				url: location.pathname,
			}),
		)
	}, [dispatch, location.pathname])

	const toogleIsOpenMobileModule = useCallback(
		(url, isOpen) => {
			navigate(url)
			toogleIsOpenModule()
			if (isOpen === true) onToggle()
		},
		[navigate, toogleIsOpenModule, onToggle],
	)

	const navigateToHome = () => {
		navigate("/home")
		isOpen && onToggle()
	}

	useEffect(() => {
		dispatch(
			setIsOpenModuleAtStart({
				url: location.pathname,
			}),
		)
	}, [dispatch, location.pathname])

	const largerThanMobileModules = modules?.map((module) => (
		<ListItem
			key={module.id}
			display='flex'
			w='auto'
			bgColor={module.secondary_color || "#FFF"}
			color={module.font_color}
			borderRadius='24'
			overflow={"hidden"}
			my={[1, 1, 1, 0]}
		>
			<Link to={module.url} onClick={toogleIsOpenModule} aria-label='Navigate to module dashboard'>
				<Box
					data-testid={module.name_pl}
					display='flex'
					boxSize={10}
					justifyContent='center'
					bgColor={module.primary_color}
					borderRadius={"50%"}
					alignItems='center'
					cursor='pointer'
					my={[0.5, 0.5, 0.5, 0]}
				>
					{getNavigationIcon(module.icon_name, false)}
				</Box>
			</Link>

			<motion.div
				{...getDisclosureProps()}
				hidden={false}
				initial={false}
				animate={{ width: module.is_open ? "auto" : 0 }}
				style={{
					overflow: "hidden",
					whiteSpace: "nowrap",
				}}
			>
				<Box
					display='flex'
					bgColor={module.secondary_color}
					h={10}
					p={[1, 1, 1, 1, 2]}
					justifyContent='center'
					alignItems='center'
					borderEndRadius='24px'
					fontSize={["xs", "sm", "sm", "sm", "md"]}
				>
					<Text fontWeight={600} mx={2}>
						{module[`name_${i18next.language}`]}
					</Text>
					<Divider
						orientation='vertical'
						borderWidth={1}
						borderColor={module.font_color}
						h={8}
						mx={[1, 1, 1, 1, 2]}
					/>

					{module.module_functionalities.map((functionality) => (
						<Link
							key={functionality.id}
							to={`${module.url}${functionality.url}`}
							style={
								location.pathname.includes(module.url + functionality.url)
									? {
											backgroundColor: module.primary_color,
											borderRadius: "24px",
											padding: "2px",
											fontWeight: "600",
									  }
									: null
							}
						>
							<Text
								data-testid={`${module.name_pl}${functionality.name_pl}`}
								display='flex'
								alignItems='center'
								aria-label='Navigate to module function'
								gap={1}
								mx={2}
							>
								{getNavigationIcon(functionality.icon_name, true)}
								{functionality[`name_${i18next.language}`]}
							</Text>
						</Link>
					))}
				</Box>
			</motion.div>
		</ListItem>
	))

	const mobileModules = modules?.map((module) => (
		<ListItem
			key={module.id}
			display='flex'
			w='auto'
			bgColor={module.secondary_color || "#FFF"}
			color={module.font_color}
			borderRadius={["20", "20", "22"]}
			overflow={"hidden"}
		>
			<Link to={module.url} onClick={toogleIsOpenModule} aria-label='Navigate to module dashboard'>
				<Box
					display='flex'
					boxSize={["38px", "42px", "45px"]}
					bgColor={module.primary_color}
					borderRadius={"50%"}
					justifyContent='center'
					alignItems='center'
					cursor='pointer'
				>
					{getNavigationIcon(module.icon_name, false)}
				</Box>
			</Link>

			<Box display='flex' bgColor={module.secondary_color} w='100%' h='auto' flexWrap='wrap'>
				<Box
					display='flex'
					w='100%'
					px={3}
					justifyContent='space-between'
					cursor='pointer'
					onClick={() => toogleIsOpenMobileModule(module.url, module.is_open)}
				>
					<Text
						display={"flex"}
						alignItems={"center"}
						fontSize={[15, 16]}
						minH={["38px", "42px", "45px"]}
						mx={[1, 2]}
						fontWeight={600}
					>
						{module[`name_${i18next.language}`]}
					</Text>
					<ChevronDownIcon
						boxSize={[7, 8]}
						my='auto'
						animation={`${
							module.is_open ? chevronAnimationUp : getChevronAnimationDown(module.is_open)
						} 0.2s linear forwards`}
					/>
				</Box>
				<Collapse startingHeight={0} in={module.is_open} style={{ width: "100%" }}>
					<Box
						display='flex'
						bgColor={module.secondary_color}
						h='auto'
						mx={[1, 3]}
						pb={[2, 3]}
						flexDirection='column'
					>
						{module.module_functionalities.map((functionality) => (
							<Box
								key={functionality.id}
								display='flex'
								bgColor={
									location.pathname.includes(module.url + functionality.url)
										? module.primary_color
										: null
								}
								borderRadius={location.pathname.includes(module.url + functionality.url) ? 24 : null}
								ms={location.pathname.includes(module.url + functionality.url) ? 3 : null}
								fontWeight={location.pathname.includes(module.url + functionality.url) ? 600 : null}
							>
								<Link
									aria-label='Navigate to module function'
									to={`${module.url}${functionality.url}`}
									onClick={onToggle}
								>
									<Flex mx={3} my={1} fontSize={[15, 17]} gap={2} alignItems={"center"}>
										{getNavigationIcon(functionality.icon_name, true)}
										<Text>{functionality[`name_${i18next.language}`]}</Text>
									</Flex>
								</Link>
							</Box>
						))}
					</Box>
				</Collapse>
			</Box>
		</ListItem>
	))

	return (
		<header style={{ position: "relative", zIndex: 1 }}>
			<nav>
				<Box
					position={"sticky"}
					w='100%'
					bgColor='#000'
					h='auto'
					color='#E5E5DF'
					borderRadius='0 0 24px 24px'
					p={3}
				>
					<Box display='flex' mb={[0, 0, 0, 4]} mt={[3, 3, 3, 0]}>
						<Button
							p={0}
							minW={8}
							boxSize={[8]}
							bgColor='#E5E5DF'
							colorScheme='#E5E5DF'
							borderRadius='50%'
							me={2}
							onClick={navigateToHome}
							aria-label='Navigate to home page'
						>
							<OutlineHome color={"#000"} boxSize={[6]} viewBox='0 1 24 24' />
						</Button>
						<Box
							w='100%'
							display='flex'
							alignItems='center'
							fontSize={["sm", "md", "md", "md", "md"]}
							fontWeight={[600]}
						>
							<Text display={["none", "block", "block", "block"]} mx={3}>
								{t("Header.welcomeText") + ` ${user}`}
							</Text>
							<Box display='flex' alignItems='center'>
								<Text ms={[3, 0]}>{dateTime?.time || "00:00"}</Text>
								<Divider
									orientation='vertical'
									borderWidth={1}
									h={4}
									mx={1}
									opacity={1}
									borderColor='#E5E5DF'
								/>
								<Text>{t("Header.weekText") + ` ${dateTime?.week || 0}`}</Text>
							</Box>
						</Box>
						{pwa && (
							<Button
								aria-label='Download PWA'
								display={"flex"}
								p='0'
								mx={2}
								minW={{ base: "29px", lg: "32px" }}
								boxSize={{ base: "29px", lg: "32px" }}
								bgColor='#E5E5DF'
								color='#000'
								borderRadius='50%'
								justifyContent='center'
								onClick={handleDownloadPwa}
							>
								<DownloadIcon />
							</Button>
						)}
						<List display={["none", "none", "none", "flex"]} me='40px' justifyContent='end' color='#FFFFFF'>
							<AppPermissionsDesktop />
							<ListItem>
								<Box
									position={"absolute"}
									right={10}
									me={1.5}
									bgColor='#000'
									px={1.5}
									borderRadius='24px'
								>
									<Popover
										placement='bottom'
										trigger='hover'
										onOpen={() => setIsOpenLangConfig(true)}
										onClose={() => setIsOpenLangConfig(false)}
									>
										<PopoverTrigger>
											<Image
												boxSize={8}
												src={getLangImage()}
												alt='Flag with configuration language'
												cursor='pointer'
											/>
										</PopoverTrigger>
										<PopoverContent w='auto' bgColor='#000' borderColor='#000' borderRadius={24}>
											<PopoverBody pt={0} pb={1} px={1}>
												<Collapse
													startingHeight={0}
													unmountOnExit={true}
													endingHeight={85}
													in={isOpenLangConfig}
													animateOpacity
												>
													{configLang}
												</Collapse>
											</PopoverBody>
										</PopoverContent>
									</Popover>
								</Box>
							</ListItem>
						</List>
						<Button
							aria-label='Logout button'
							display={["none", "none", "none", "flex"]}
							h='32px'
							minW='32px'
							p='0'
							bgColor='#C31531'
							color='#FFFFFF'
							borderRadius='50%'
							justifyContent='center'
							_hover={{
								bgColor: "#E20E0E",
							}}
							onClick={handleLogout}
						>
							<IoPower boxSize={5} />
						</Button>
						<Button
							aria-label='Burger menu button'
							p='0'
							h='28px'
							minW='28px'
							borderRadius='50%'
							display={["block", "block", "block", "none"]}
							bgColor='#E5E5DF'
							color='#000'
							onClick={onToggle}
						>
							<Box
								w='60%'
								h='2px'
								borderRadius='32%'
								bgColor='#000'
								mx='auto'
								my='0.5'
								animation={`${
									isOpen ? firstLineAnimationNormal : firstLineAnimationReverse
								} 0.4s linear forwards`}
							/>
							<Box
								w='60%'
								h='2px'
								borderRadius='32%'
								bgColor='#000'
								mx='auto'
								my='0.5'
								animation={`${
									isOpen ? secondLineAnimationNormal : secondLineAnimationReverse
								} 0.4s linear forwards`}
							/>
							<Box
								w='60%'
								h='2px'
								borderRadius='32%'
								bgColor='#000'
								mx='auto'
								my='0.5'
								animation={`${
									isOpen ? thirdLineAnimationNormal : thirdLineAnimationReverse
								} 0.4s linear forwards`}
							/>
						</Button>
					</Box>
					<Collapse startingHeight={startingHeight} in={isOpen}>
						<List
							display={"flex"}
							flexDirection={["column", "column", "column", "row"]}
							color='#000'
							gap={[3, 3, 3, 2]}
							mt={[7, 8, 9, 0]}
							mb={[5, 4, 4, 0]}
						>
							{isLargerThanMobile ? largerThanMobileModules : mobileModules}
						</List>
						<Divider
							display={["flex", "flex", "flex", "none"]}
							borderWidth={1}
							borderColor='#BEBEBE'
							opacity={1}
						/>

						<Box display={["flex", "flex", "flex", "none"]} justifyContent='end' mt={3}>
							<AppPermissionsMobile onToggle={onToggle} />
							<Box mx={2} display='inline-flex'>
								<motion.div
									{...getDisclosureProps()}
									hidden={false}
									initial={false}
									animate={{ width: isOpenLangConfig ? "auto" : 0 }}
									style={{
										overflow: "hidden",
										whiteSpace: "nowrap",
									}}
								>
									{configLang}
								</motion.div>
								<Button
									aria-label='Configuration language'
									display='flex'
									h='30px'
									bgColor='#E5E5DF'
									minW='30px'
									p={0}
									borderRadius='50%'
									justifyContent='center'
									onClick={handleConfigLang}
								>
									<Image boxSize='100%' src={getLangImage()} alt='Flag with configuration language' />
								</Button>
							</Box>
							<Button
								aria-label='Logout button'
								display={["flex", "flex", "flex", "none"]}
								h='30px'
								p='0'
								minW='30px'
								bgColor='#C31531'
								color='#FFFFFF'
								borderRadius='50%'
								justifyContent='center'
								_hover={{
									bgColor: "#E20E0E",
								}}
								onClick={handleLogout}
							>
								<IoPower boxSize={5} />
							</Button>
						</Box>
					</Collapse>
				</Box>
			</nav>
		</header>
	)
}

export default memo(Header)
