import { useLocation } from "react-router-dom"
import { Box, Container, Divider, Flex, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import i18next from "i18next"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import WarehouseNavigationButtons from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseNavigationButtons"
import { getMaterials } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import { clearMaterials } from "../../../../../store/modules/warehouse/warehouses/warehouses-slice"
import LoadingHOC from "../../../../../components/common/LoadingHOC"
import StaticSearch from "../../../../../components/common/StaticSearch"
import WarehouseActionButtons from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/WarehouseActionButtons"
import MaterialsList from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/MaterialsList"
import WarehouseNavigation from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseNavigation"
import NoData from "../../../../../components/common/NoData"
import QuantitySelect from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/QuantitySelect"

const WarehouseMaterial = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const materialsList = useSelector((state) => state.warehouses.materials)
	const [materials, setMaterials] = useState()
	const [materialsCopy, setMaterialsCopy] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const [searchingTerm, setSearchingTerm] = useState("")
	const [recordsPerPage, setRecordsPerPage] = useState()

	useEffect(() => {
		if (!materialsCopy) return
		setData(materialsCopy)
	}, [recordsPerPage])

	const setData = (initData) => {
		setMaterials(initData.slice(0, recordsPerPage))
		setMaterialsCopy(initData)
	}

	useEffect(() => {
		setIsLoading(true)
		const splittedPath = location.pathname.split("/")
		dispatch(getMaterials(splittedPath[splittedPath.length - 1])).then((data) => {
			setData(data.payload.materials)
			setIsLoading(false)
		})

		return () => dispatch(clearMaterials())
	}, [i18next.language])

	const handleSetResults = (results) => {
		setData(results)
	}

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Flex justifyContent='space-between' flexWrap={{ base: "wrap", md: "nowrap" }} mt={2} gap={{ md: 2 }}>
					<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 0]}>
						<WarehouseNavigation />
					</Box>
					<WarehouseNavigationButtons />
				</Flex>
				<Divider borderColor='#FFF' borderWidth='2px' my={4} />
				<Flex
					w='100%'
					alignItems={"center"}
					gap={5}
					justifyContent={"space-between"}
					flexWrap={{ base: "wrap", lg: "nowrap" }}
				>
					{materialsList && (
						<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
							{materialsList.name}
						</Text>
					)}
					<Flex
						w={{ base: "100%", lg: "auto" }}
						justifyContent={"end"}
						gap={2}
						flexWrap={{ base: "wrap", md: "nowrap" }}
					>
						<QuantitySelect setRecordsPerPage={setRecordsPerPage} maxLength={materialsCopy?.length} />
						<Flex>
							<StaticSearch
								fieldsToFilter={["name"]}
								isDisabled={!materialsList?.materials?.length}
								searchText={searchingTerm}
								setSearchText={setSearchingTerm}
								handleSetResults={handleSetResults}
								originalArray={materialsList?.materials}
							/>
						</Flex>
						<WarehouseActionButtons />
					</Flex>
				</Flex>
				<LoadingHOC isLoading={!isLoading}>
					<Box my={10} pb={5}>
						{materials?.length ? (
							<MaterialsList
								parameterNames={materialsList?.parameter_names}
								materials={materials}
								setMaterials={setMaterials}
								materialsList={materialsList?.materials}
								materialsCopy={materialsCopy}
								setMaterialsCopy={setMaterialsCopy}
								recordsPerPage={recordsPerPage}
							/>
						) : (
							<NoData />
						)}
					</Box>
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default WarehouseMaterial
