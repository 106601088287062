import { Button, Flex } from "@chakra-ui/react"
import { memo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

const WarehouseNavigationButtons = () => {
	const { t } = useTranslation("global")
	const navigate = useNavigate()
	const location = useLocation()
	const [splittedPathname] = useState(location.pathname.split("/"))
	const [initPathname] = useState(`/${splittedPathname[1]}/${splittedPathname[2]}/${splittedPathname[3]}`)
	const [isDocumentsPlace] = useState(splittedPathname[splittedPathname.length - 1] === "documents")
	const [isAddPlace] = useState(splittedPathname[splittedPathname.length - 1] === "add")
	const [isRemovePlace] = useState(splittedPathname[splittedPathname.length - 1] === "remove")
	const [isInventoryPlace] = useState(splittedPathname[splittedPathname.length - 1] === "inventory")
	const [isMagazinePlace] = useState(!isDocumentsPlace && !isAddPlace && !isRemovePlace && !isInventoryPlace)

	const handleNavigate = (path) => {
		navigate(`${initPathname}${path}`)
	}

	const navigationButton = (place, primary_color, secondary_color, path, fieldName) => (
		<Button
			h={["25px", "30px", "35px"]}
			bgColor={place ? "#FFF" : primary_color}
			color={place ? primary_color : "#FFF"}
			border={place ? `1px solid ${primary_color}` : "none"}
			fontSize={[13, 14, 15, 16]}
			_hover={{ bgColor: secondary_color, color: "#FFF" }}
			fontWeight={place ? "600" : "400"}
			onClick={() => handleNavigate(path)}
		>
			{t(`Warehouses.${fieldName}`)}
		</Button>
	)

	return (
		<Flex gap={2} flexWrap={"wrap"} justifyContent={"end"}>
			{navigationButton(isMagazinePlace, "#2C7A7B", "teal.700", "", "warehouse")}
			{navigationButton(isDocumentsPlace, "#2C7A7B", "teal.700", "/documents", "warehouseDocuments")}
			{navigationButton(isAddPlace, "#38A169", "green.600", "/add", "addToWarehouse")}
			{navigationButton(isRemovePlace, "#C53030", "red.700", "/remove", "takeFromWarehouse")}
			{navigationButton(isInventoryPlace, "#2C7A7B", "teal.700", "/inventory", "inventory")}
		</Flex>
	)
}

export default memo(WarehouseNavigationButtons)
