import { useTranslation } from "react-i18next"
import { useCallback } from "react"
import * as Yup from "yup"
import { memo } from "react"
import i18next from "i18next"

import EditMaterial from "../EditMaterial/EditMaterial"
import PreviewMaterial from "../PreviewMaterial/PreviewMaterial"
import { warningToast } from "../../../../../utils/notifications/warningToast"
import FormikForm from "../../../../common/FormikForm"

const MaterialForm = ({ isPatchMethod = false, initialValues, isEditPreview = true, initialTab = 0, onSubmit }) => {
	const [t] = useTranslation("global")

	const calculateTotalFilesSize = useCallback((values) => {
		let totalSize = 0

		if (values.image) {
			if (typeof values.image === "object") totalSize += Number(values.image.size)
		}

		values.materials.forEach((material) => {
			if (material.image) {
				if (typeof material.image === "object") totalSize += Number(material.image.size)
			}
			material.parameter_counter.forEach((parameter) => {
				if (!parameter.icon) return
				if (typeof parameter.icon === "object") totalSize += Number(parameter.icon.size)
			})
		})

		values.parameters.forEach((parameter) => {
			if (!parameter.icon) return
			if (typeof parameter.icon === "object") totalSize += Number(parameter.icon.size)
		})

		const totalSizeInMB = (totalSize / (1024 * 1024)).toFixed(0)

		return Number(totalSizeInMB)
	}, [])

	const submitMiddleware = useCallback(
		(values) => {
			const data = { ...values }
			delete data.newParameter

			if (calculateTotalFilesSize(data) >= 100) {
				warningToast(t("Materials.exceededFilesLimit"))
				return
			}
			const initValues = { ...initialValues }
			delete initValues.newParameter
			return { data, initValues }
		},
		[calculateTotalFilesSize],
	)

	return (
		<FormikForm
			isPatchMethod={isPatchMethod}
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				parameters: Yup.array().of(
					Yup.object().shape({
						[`name_${i18next.language}`]: Yup.string().required(t("Materials.parameterNameRequireError")),
						values: Yup.array().of(
							Yup.object().shape({
								[`name_${i18next.language}`]: Yup.string().required(
									t("Materials.parameterValueRequireError"),
								),
							}),
						),
					}),
				),
				materials: Yup.array().of(
					Yup.object().shape({
						[`name_${i18next.language}`]: Yup.string().required(t("Materials.materialNameRequired")),
					}),
				),
				[`name_${i18next.language}`]: Yup.string().required(t("Materials.groupNameRequired")),
			})}
			middleware={submitMiddleware}
			onSubmit={onSubmit}
		>
			{isEditPreview ? <EditMaterial /> : <PreviewMaterial initialTab={initialTab} />}
		</FormikForm>
	)
}

export default memo(MaterialForm)
