import { Navigate, Outlet } from "react-router-dom"

import { useSelector } from "react-redux"

const HasRolesRoutes = ({ allowedRoles }) => {
	const role = useSelector((state) => state.auth.role)

	return allowedRoles?.includes(role) ? <Outlet /> : <Navigate to='/' />
}

export default HasRolesRoutes
