import { createSlice } from "@reduxjs/toolkit"
import { getMaterialGroups, getMaterials } from "../../../../actions/modules/warehouse/warehouses/warehouses-actions"
/**
 * Slice for warehouses
 */
const warehousesSlice = createSlice({
	name: "employees",
	initialState: {
		materialGroups: null,
		materials: null,
	},
	reducers: {
		clearMaterialGroups(state) {
			state.materialGroups = null
		},
		clearMaterials(state) {
			state.materials = null
		},
		setSortedGroups(state, action) {
			state.materialGroups = action.payload
		},
		setSortedMaterials(state, action) {
			state.materials.materials = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMaterialGroups.fulfilled, (state, action) => {
				state.materialGroups = action.payload
			})
			.addCase(getMaterials.fulfilled, (state, action) => {
				state.materials = action.payload
			})
	},
})

export const { clearMaterialGroups, clearMaterials, setSortedGroups, setSortedMaterials } = warehousesSlice.actions

export default warehousesSlice
