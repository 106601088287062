import { Box, Flex, GridItem, Image, Text } from "@chakra-ui/react"
import { memo } from "react"
import { AddIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import { getMaterials } from "../../../../../actions/modules/orders/shop/shop-actions"

const MaterialGroups = ({
	materialGroups,
	isDisabled,
	setIsCustomProduct,
	setCreateOrderState,
	setShowedItems,
	orderId,
	setSearchValue,
	setResources,
}) => {
	const { t, i18n } = useTranslation("global")
	const dispatch = useDispatch()

	const handleSelectMaterialGroup = (material) => {
		if (isDisabled) return
		const materialGroup = {
			id: material.id,
			image: material.image,
			name: material.name,
			order_id: Number(orderId),
		}
		setShowedItems([materialGroup])
		setCreateOrderState(1)
		setSearchValue("")
		setResources([])
		dispatch(getMaterials(material.id))
	}

	const handleCreateCustomProduct = () => {
		if (isDisabled) return
		setIsCustomProduct(true)
		setCreateOrderState(3)
		setShowedItems([{ lang: i18n.language, order_id: Number(orderId), count: 1 }])
	}

	return (
		<>
			{materialGroups?.map((material) => (
				<GridItem
					key={material.id}
					minH={["140px", "190px", "200px", "215px", "230px"]}
					minW={["70px", "140px", "140px", "150px", "190px"]}
					bgColor='#FFF'
					borderRadius='4px'
					boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
					cursor={isDisabled ? "not-allowed" : "pointer"}
					position='relative'
					overflow='hidden'
					display='flex'
					flexDirection='column'
					py={2}
					onClick={() => handleSelectMaterialGroup(material)}
				>
					<Box position='relative' flex='1' maxH={"100%"} maxW={"100%"}>
						{material.image === null ? (
							<LackImageIcon
								position='absolute'
								top={0}
								left={0}
								display='flex'
								h='100%'
								w='100%'
								objectFit='contain'
								alt='Lack of material group image'
							/>
						) : (
							<Image
								position='absolute'
								top={0}
								left={0}
								display='flex'
								h='100%'
								w='100%'
								objectFit='contain'
								alt='Material group image'
								src={material.image}
							/>
						)}
					</Box>
					<Text
						position='relative'
						px={1}
						zIndex={1}
						bgColor='rgba(255, 255, 255, 0.8)'
						flexShrink={0}
						mt='auto'
						w='auto'
						h='auto'
						textAlign='center'
						fontSize={[13, 14, 15, 16]}
						fontWeight={600}
					>
						{material.name}
					</Text>
				</GridItem>
			))}
			<GridItem>
				<Flex
					minH={["140px", "190px", "200px", "215px", "230px"]}
					minW={["70px", "140px", "140px", "150px", "190px"]}
					justifyContent='center'
					alignItems='center'
					bgColor='#FFF'
					borderRadius='4px'
					boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
					cursor={isDisabled ? "not-allowed" : "pointer"}
					onClick={handleCreateCustomProduct}
				>
					<Box textAlign='center'>
						<AddIcon
							textAlign='center'
							display='flex'
							justifyContent='center'
							mx='auto'
							boxSize={[6, 6, 7, 9, 10]}
							color='#2B807E'
						/>
						<Text
							fontSize={[11, 13, 14, 15, 16]}
							textAlign='center'
							fontWeight={600}
							my={2}
							color='#2B807E'
							textTransform='uppercase'
							justifyContent='center'
						>
							{t("Order.newProduct")}
						</Text>
						<Text fontSize={[11, 13, 14, 15, 16]} textAlign='center' w='75%' mx='auto'>
							{t("Order.emptyProduct")}
						</Text>
					</Box>
				</Flex>
			</GridItem>
		</>
	)
}

export default memo(MaterialGroups)
