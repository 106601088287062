import { Button } from "@chakra-ui/react"
import jsPDF from "jspdf"
import { memo } from "react"

const PDFButton = () => {
	const generatePDF = () => {
		const doc = new jsPDF()

		doc.text("Hello, world!", 10, 10)

		doc.save("sample.pdf")
	}

	return (
		<Button
			fontSize={[13, 14, 15, 16]}
			fontWeight={"400"}
			h={["25px", "35px", "40px"]}
			bgColor={"#FFF"}
			borderRadius={"4px"}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			onClick={generatePDF}
		>
			PDF
		</Button>
	)
}

export default memo(PDFButton)
