import { Box, Flex, GridItem, Image, Text, Textarea } from "@chakra-ui/react"
import React, { memo, useCallback } from "react"
import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import { useTranslation } from "react-i18next"
import LanguageSelect from "../../../Orders/Shop/LanguageSelect/LanguageSelect"

const CurrentItem = ({ currentItems, selectedItemsRef, isCustomProduct, isDisabled, setShowedItems }) => {
	const { t } = useTranslation("global")

	const handleChangeDescription = useCallback(
		(e, id) => {
			const items = currentItems
			items[id].description = e.target.value
			setShowedItems([...items])
		},
		[currentItems],
	)

	const handleChangeLang = useCallback(
		(lang, id) => {
			const items = currentItems
			items[id].selectedLang = lang
			setShowedItems([...items])
		},
		[currentItems],
	)

	const getCurrentLang = useCallback(
		(lang, id) =>
			!Array.isArray(lang) ? (
				<Box
					display='flex'
					flexWrap='wrap'
					alignItems='center'
					border='1px solid var(--gray-200, #E2E8F0)'
					borderRadius='6px'
					boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
					p={["4px", "5px", "8px"]}
				>
					<Image
						me={2}
						alt='Flag image'
						boxSize={["15px", "17px", "20px"]}
						src={require(
							`../../../../../assets/icons/shop/${
								lang === "pl" ? "PolandFlag" : lang === "en" ? "EnglandFlag" : "NetherlandFlag"
							}.webp`,
						)}
					/>
					<Text fontSize={[12, 13, 14, 15, 16]}>
						{t(
							`Order.${
								lang === "pl"
									? "polishLanguage"
									: lang === "en"
									  ? "englishLanguage"
									  : "netherlandLanguage"
							}`,
						)}
					</Text>
				</Box>
			) : (
				<LanguageSelect
					isDisabled={currentItems && currentItems.length === 3}
					id={id}
					handleChangeLang={handleChangeLang}
					options={lang}
				/>
			),
		[handleChangeLang, currentItems, t],
	)

	return currentItems?.map((item, showedItemsId) => (
		<React.Fragment key={showedItemsId}>
			<GridItem
				ref={selectedItemsRef}
				maxH={showedItemsId > 1 && ["136px", "157px", "198px", "210px", "230px"]}
				p={2}
				bgColor='#FFF'
				borderRadius='4px'
				boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
				overflow={!isCustomProduct && showedItemsId > 1 ? "auto" : "hidden"}
				minH={["140px", "190px", "200px", "215px", "230px"]}
				minW={["70px", "140px", "140px", "150px", "190px"]}
				cursor={isDisabled ? "not-allowed" : "pointer"}
				position='relative'
				display='flex'
				flexDirection='column'
				py={2}
			>
				{!isCustomProduct ? (
					showedItemsId < 2 ? (
						<>
							<Box position='relative' flex='1' maxH={"100%"} maxW={"100%"}>
								{item.image === null ? (
									<LackImageIcon
										position='absolute'
										top={0}
										left={0}
										display='flex'
										h='100%'
										w='100%'
										objectFit='contain'
										alt='Lack of show items image'
									/>
								) : (
									<Image
										position='absolute'
										top={0}
										left={0}
										display='flex'
										h='100%'
										w='100%'
										objectFit='contain'
										alt='Show item image'
										src={item.image}
									/>
								)}
							</Box>
							<Text
								position='relative'
								px={1}
								zIndex={1}
								bgColor='rgba(255, 255, 255, 0.8)'
								flexShrink={0}
								mt='auto'
								w='auto'
								h='auto'
								textAlign='center'
								fontSize={[13, 14, 15, 16]}
								fontWeight={600}
							>
								{item.name}
							</Text>
						</>
					) : (
						item.map((parameter, index) => (
							<React.Fragment key={index}>
								{index === 0 && (
									<Flex justifyContent='center' alignItems='center'>
										<Text
											fontSize={[13, 14, 15, 16]}
											textAlign='center'
											maxW={["80px", "100px", "140px", "150px", "180px"]}
											mt={[0, 0, 2]}
											fontWeight={600}
										>
											{parameter.parameterName}
										</Text>
									</Flex>
								)}
								<Box p={[1, 1.5, 2]}>
									<Flex
										key={parameter.id}
										w='100%'
										p={[1, 2, 1.5, 2]}
										justifyContent='center'
										bgColor='#F2F2EF'
										borderRadius='4px'
									>
										<Text
											textAlign='center'
											fontSize={[13, 14, 15, 16]}
											maxW={["30px", "50px", "90px", "90px", "120px"]}
										>
											{parameter.name}
										</Text>
									</Flex>
								</Box>
							</React.Fragment>
						))
					)
				) : (
					<Box
						w={["100%"]}
						maxH={["125px", "100px", "100px", "160px", "130px"]}
						overflow={["auto", "visible"]}
					>
						{getCurrentLang(item.lang, showedItemsId)}
						<Textarea
							isDisabled={isDisabled}
							minH='40px'
							h={["auto", "100%"]}
							resize={"none"}
							fontSize={[13, 14, 15, 16]}
							placeholder={t("Order.addDescription")}
							onChange={(e) => handleChangeDescription(e, showedItemsId)}
							my={3}
						/>
					</Box>
				)}
			</GridItem>
		</React.Fragment>
	))
}

export default memo(CurrentItem)
