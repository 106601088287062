import { Box, Grid, GridItem, Divider, Heading, Text } from "@chakra-ui/layout"
import { Checkbox } from "@chakra-ui/checkbox"
import { Input, InputGroup } from "@chakra-ui/input"
import { memo } from "react"
import { FastField, Field } from "formik"
import { useTranslation } from "react-i18next"
import { useDisclosure } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"

import SelectWithSearching from "../../../../common/SelectWithSearching"
import InputColor from "../../../../common/InputColor"
import FormikForm from "../../../../common/FormikForm"
import TextFieldWithDebounce from "../../../../common/TextFieldWithDebounce"
import LoadingHOC from "../../../../common/LoadingHOC"
import AlertCancelChanges from "../../../../common/AlertCancelChanges"
import PasswordFieldWithDebounce from "../../../../common/PasswordFieldWithDebounce"
import ActionButtons from "./ActionButtons"

const BasicDatesForm = ({ isPatchMethod, initialValues, employeeInitialDates, onSubmit }) => {
	const { t } = useTranslation("global")
	const { isOpen, onClose, onOpen } = useDisclosure()
	const navigate = useNavigate()
	const location = useLocation()

	const handleValidPhoneNumber = (event, name) => {
		if (
			(name === "phone" || name === "pesel") &&
			(event.key === "." || event.key === "," || event.key === "-" || event.key === "+" || event.key === "/")
		) {
			event.preventDefault()
		}
	}

	const getTitle = (name) => (
		<GridItem gap={10}>
			<Text
				whiteSpace={"pre-wrap"}
				wordBreak={"break-all"}
				fontSize={[13, 14, 15, 16]}
				color='teal.600'
				mb={["1", "1", "1", "2"]}
			>
				{t(`Employees.${name}`)}
			</Text>
		</GridItem>
	)

	const getField = ({ field, name, placeholder, type = "text", ariaLabel }) => (
		<GridItem mb={[2]}>
			{field || (
				<FastField name={name}>
					{({ form }) => (
						<TextFieldWithDebounce
							disabled={form.isSubmitting}
							name={name}
							type={type}
							placeholder={placeholder}
							fontSize={[13, 14, 15, 16]}
							h={["35px", "35px", "40px"]}
							aria-label={ariaLabel}
							onKeyDown={(e) => handleValidPhoneNumber(e, name)}
						/>
					)}
				</FastField>
			)}
		</GridItem>
	)

	const handleCancelForm = () => {
		const path = location.pathname.split("/")
		navigate(`/${path[1]}/${path[2]}`)
	}

	const getValidationSchema = () => {
		if (isPatchMethod) {
			return Yup.object().shape({
				first_name: Yup.string().required(t("Employees.firstNameRequired")),
				last_name: Yup.string().required(t("Employees.lastNameRequired")),
				initials: Yup.string().required(t("Employees.initialsRequired")),
				login: Yup.string().required(t("Employees.loginRequired")),
				permissions_for_users: Yup.string().required(t("Employees.accessAppRequired")),
				language: Yup.string().required(t("Employees.languageRequired")),
				background_color: Yup.string().matches(/^#(?:[0-9a-fA-F]{3}){1,2}$/, t("Employees.invalidColorFormat")),
				text_color: Yup.string().matches(/^#(?:[0-9a-fA-F]{3}){1,2}$/, t("Employees.invalidColorFormat")),
			})
		}

		return Yup.object().shape({
			first_name: Yup.string().required(t("Employees.firstNameRequired")),
			last_name: Yup.string().required(t("Employees.lastNameRequired")),
			initials: Yup.string().required(t("Employees.initialsRequired")),
			login: Yup.string().required(t("Employees.loginRequired")),
			password: Yup.string()
				.required(t("Employees.passwordRequired"))
				.min(8, t("Login.main.passwordTooShort"))
				.test("has-lowercase", t("Login.main.passwordWithoutSmallLetter"), (value) => /[a-z]/.test(value))
				.test("has-uppercase", t("Login.main.passwordWithoutBigLetter"), (value) => /[A-Z]/.test(value))
				.test("has-special-char", t("Login.main.passwordWithoutSpecialChar"), (value) =>
					/[!@#$%^&*()\-=_+[\]{}|\\;:'",.<>/?]/.test(value),
				),
			permissions_for_users: Yup.string().required(t("Employees.accessAppRequired")),
			language: Yup.string().required(t("Employees.languageRequired")),
			background_color: Yup.string().matches(/^#(?:[0-9a-fA-F]{3}){1,2}$/, t("Employees.invalidColorFormat")),
			text_color: Yup.string().matches(/^#(?:[0-9a-fA-F]{3}){1,2}$/, t("Employees.invalidColorFormat")),
		})
	}

	return (
		<Box
			w='100%'
			minH={["300px", "400px", "500px", "700px"]}
			h={!employeeInitialDates || !initialValues ? ["300px", "400px", "500px", "700px"] : "auto"}
			position='relative'
			bgColor='#FFF'
			p={[4, 6]}
			mb={5}
			borderTopRadius={["none", "none", "none", "4px"]}
			borderBottomRadius={"4px"}
			boxShadow=' 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
		>
			<Heading fontSize={[14, 15, 16, 17]} fontWeight={400}>
				{t("Employees.datesAboutAppUser")}
			</Heading>
			<Text fontSize={[13, 14, 15, 16]} color='teal.600'>
				Tutaj można dodać jakąś treść
			</Text>
			<Divider my={2} w='100%' borderColor='#D4D4CD' borderWidth='1px' />
			<LoadingHOC isLoading={employeeInitialDates && initialValues}>
				<FormikForm
					isPatchMethod={isPatchMethod}
					initialValues={initialValues}
					onSubmit={onSubmit}
					validationSchema={getValidationSchema()}
				>
					<Grid templateColumns={["250px", "300px", "220px 300px"]} gap={[0, 0, "5px 20px"]} mt={[3, 5, 7]}>
						{getTitle("firstName")}
						{getField({ name: "first_name", placeholder: t("Employees.addFirstName") })}
						{getTitle("lastName")}
						{getField({ name: "last_name", placeholder: t("Employees.addLastName") })}
						{getTitle("initials")}
						{getField({ name: "initials", placeholder: t("Employees.addInitials") })}
						{getTitle("email")}
						{getField({ name: "email", placeholder: t("Employees.addEmail") })}
						{getTitle("phone")}
						{getField({
							type: "number",
							name: "phone",
							placeholder: t("Employees.addPhoneNumber"),
						})}
						{getTitle("login")}
						{getField({ name: "login", placeholder: t("Employees.addLogin") })}
						{!isPatchMethod && getTitle("password")}
						{!isPatchMethod &&
							getField({
								name: "password",
								field: (
									<FastField name='password'>
										{({ form }) => (
											<PasswordFieldWithDebounce
												name={"password"}
												isDisabled={form.isSubmitting}
												autoComplete='new-password'
												placeholder={t("Employees.addPassword")}
												fontSize={[13, 14, 15, 16]}
												h={["35px", "35px", "40px"]}
												w={["250px", "300px"]}
											/>
										)}
									</FastField>
								),
							})}
						<GridItem colSpan={[1, 1, 2]}>
							<Divider mt={4} mb={[5, 5, 7]} w='100%' borderColor='#D4D4CD' borderWidth={"1%"} />
						</GridItem>
						{getTitle("contractor")}
						{getField({
							name: "contractor",
							field: (
								<FastField name='contractor'>
									{({ form }) => (
										<SelectWithSearching
											values={employeeInitialDates?.contractors}
											isDisabled={form.isSubmitting}
											name={"contractor"}
											placeholder={t("Employees.selectContractor")}
											h={["35px", "35px", "40px"]}
											w={["250px", "300px"]}
										/>
									)}
								</FastField>
							),
						})}
						{getTitle("coffee")}
						{getField({
							name: "coffee",
							field: (
								<FastField name='coffee'>
									{({ form }) => (
										<SelectWithSearching
											values={employeeInitialDates?.coffee}
											isDisabled={form.isSubmitting}
											name={"coffee"}
											placeholder={t("Employees.selectCoffee")}
											h={["35px", "35px", "40px"]}
											w={["250px", "300px"]}
										/>
									)}
								</FastField>
							),
						})}
						{getTitle("permissionsForUsers")}
						{getField({
							name: "permissions_for_users",
							field: (
								<FastField name='permissions_for_users'>
									{({ form }) => (
										<SelectWithSearching
											values={employeeInitialDates?.permissions_for_users}
											isDisabled={form.isSubmitting}
											name={"permissions_for_users"}
											placeholder={t("Employees.selectAccessApp")}
											h={["35px", "35px", "40px"]}
											w={["250px", "300px"]}
										/>
									)}
								</FastField>
							),
						})}
						{getTitle("function")}
						{getField({
							name: "function",
							field: (
								<FastField name='function'>
									{({ form }) => (
										<SelectWithSearching
											values={employeeInitialDates?.functions}
											isDisabled={form.isSubmitting}
											name={"function"}
											placeholder={t("Employees.selectFunction")}
											h='40px'
											w={["250px", "300px"]}
										/>
									)}
								</FastField>
							),
						})}
						{getTitle("group")}
						{getField({
							name: "group",
							field: (
								<FastField name='group'>
									{({ form }) => (
										<SelectWithSearching
											values={employeeInitialDates?.groups}
											isDisabled={form.isSubmitting}
											name={"group"}
											placeholder={t("Employees.selectGroup")}
											h={["35px", "35px", "40px"]}
											w={["250px", "300px"]}
										/>
									)}
								</FastField>
							),
						})}
						{getTitle("language")}
						{getField({
							name: "language",
							field: (
								<FastField key={employeeInitialDates?.language[0].name} name='language'>
									{({ form }) => (
										<SelectWithSearching
											values={employeeInitialDates?.language}
											isDisabled={form.isSubmitting}
											name={"language"}
											placeholder={t("Employees.selectLanguage")}
											h={["35px", "35px", "40px"]}
											w={["250px", "300px"]}
										/>
									)}
								</FastField>
							),
						})}
						{getTitle("employedSince")}
						{getField({
							name: "employed_since",
							type: "date",
							ariaLabel: "Employed since input date",
						})}
						{getTitle("released")}
						{getField({
							name: "fired",
							type: "date",
							ariaLabel: "Fired input date",
						})}
						<GridItem colSpan={[1, 1, 2]}>
							<Divider mt={4} mb={[5, 5, 7]} w='100%' borderColor='#D4D4CD' borderWidth={"1%"} />
						</GridItem>
						{getTitle("dateOfBirth")}
						{getField({
							name: "date_of_birth",
							type: "date",
							ariaLabel: "Date of birth input date",
						})}
						{getTitle("pesel")}
						{getField({
							name: "pesel",
							type: "number",
							placeholder: t("Employees.addPesel"),
						})}
						{getTitle("ordersForOthers")}
						{getField({
							name: "orders_for_others",
							field: (
								<FastField name='orders_for_others'>
									{({ form }) => (
										<InputGroup>
											<Field
												as={Checkbox}
												isDisabled={form.isSubmitting}
												aria-label='checkbox'
												name='orders_for_others'
												isChecked={form.values.orders_for_others}
												colorScheme='teal'
												size={["md", "md", "lg"]}
											/>
										</InputGroup>
									)}
								</FastField>
							),
						})}
						{getTitle("subjectToISO")}
						{getField({
							name: "subject_to_ISO",
							field: (
								<FastField name='subject_to_ISO'>
									{({ form }) => (
										<InputGroup>
											<Field
												as={Checkbox}
												isDisabled={form.isSubmitting}
												aria-label='checkbox'
												name='subject_to_ISO'
												colorScheme='teal'
												isChecked={form.values.subject_to_ISO}
												size={["md", "md", "lg"]}
											/>
										</InputGroup>
									)}
								</FastField>
							),
						})}
						<GridItem colSpan={[1, 1, 2]}>
							<Divider mt={4} mb={[5, 5, 7]} w='100%' borderColor='#D4D4CD' borderWidth={"1%"} />
						</GridItem>
						{getTitle("backgroundColor")}
						{getField({
							name: "background_color",
							field: (
								<FastField name='background_color'>
									{({ form }) => (
										<InputColor
											name={"background_color"}
											isDisabled={form.isSubmitting}
											placeholder={t("Employees.addBackgroundColor")}
											fontSize={[13, 14, 15, 16]}
											w={["250px", "300px"]}
										/>
									)}
								</FastField>
							),
						})}
						{getTitle("textColor")}
						{getField({
							name: "text_color",
							field: (
								<FastField name='text_color'>
									{({ form }) => (
										<InputColor
											name={"text_color"}
											isDisabled={form.isSubmitting}
											placeholder={t("Employees.addTextColor")}
											fontSize={[13, 14, 15, 16]}
											w={["250px", "300px"]}
										/>
									)}
								</FastField>
							),
						})}
						{getTitle("colorsExample")}
						{getField({
							name: "colors_example",
							field: (
								<InputGroup>
									<Field>
										{({ form }) => (
											<Input
												aria-label='Color example input'
												color={form.values.text_color}
												bgColor={form.values.background_color}
												w={["250px", "300px"]}
												mb={["1", "1", "1", "2"]}
												fontSize={[13, 14, 15, 16]}
												readOnly
												h={["35px", "35px", "40px"]}
												value={t("Employees.colorsExample")}
											/>
										)}
									</Field>
								</InputGroup>
							),
						})}
					</Grid>
					<ActionButtons onOpen={onOpen} />
				</FormikForm>
			</LoadingHOC>
			<AlertCancelChanges isOpen={isOpen} onClose={onClose} handleConfirmCancel={handleCancelForm} />
		</Box>
	)
}

export default memo(BasicDatesForm)
