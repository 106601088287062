import { FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { Field, useField } from "formik"
import React, { useState } from "react"
import { useEffect } from "react"
import { debounce } from "lodash"
import { useCallback } from "react"

const TextFieldWithDebounce = ({ label, ...props }) => {
	const [field, meta, helpers] = useField(props)
	const [fieldValue, setFieldValue] = useState(field.value)

	useEffect(() => {
		if (field.value !== fieldValue) {
			setFieldValue(field.value)
		}
	}, [field.value])

	const fieldProps = {
		...field,
		...props,
		value: fieldValue,
		onChange: (e) => setFieldValue(e.target.value),
		onBlur: () => {},
	}

	delete fieldProps?.name

	const handleOnChangeValue = useCallback(
		debounce((value) => helpers.setValue(value), 300),
		[helpers],
	)

	useEffect(() => {
		handleOnChangeValue(fieldValue)
	}, [fieldValue])

	return (
		<FormControl mb={["1", "1", "1", "2"]} isInvalid={meta.error && meta.touched}>
			<FormLabel my='0'>{label}</FormLabel>
			<Field as={Input} my='0' autoComplete='true' {...fieldProps} />
			<FormErrorMessage fontSize={[11, 12, 13, 13, 14]} my='0'>
				{meta.error}
			</FormErrorMessage>
		</FormControl>
	)
}

export default TextFieldWithDebounce
