import { Box, Button, Container, Divider, Flex, Heading } from "@chakra-ui/react"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import WarehouseNavigationButtons from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseNavigationButtons"
import WarehouseNavigation from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseNavigation"
import { useTranslation } from "react-i18next"
import DocumentAccordion from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseDocuments/DocumentAccordion"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import OrderCreator from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseDocuments/OrderCreator"

const WarehouseDocument = () => {
	const { t } = useTranslation("global")
	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Flex justifyContent='space-between' flexWrap={{ base: "wrap", md: "nowrap" }} mt={2} gap={{ md: 2 }}>
					<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 0]}>
						<WarehouseNavigation />
					</Box>
					<WarehouseNavigationButtons />
				</Flex>
				<Divider borderColor='#FFF' borderWidth='2px' my={4} />
				<Flex justifyContent={"space-between"} alignItems={"center"} mb={5}>
					<Heading as={"h3"} fontSize={[13, 14, 15, 16]} fontWeight={500}>
						{t("Warehouses.addToWarehouse")}
					</Heading>
					<Button
						aria-label='Save button'
						type='submit'
						bgColor='green.600'
						_hover={{ backgroundColor: "green.700" }}
						colorScheme='green'
						color='#FFF'
						h={["30px", "35px", "40px"]}
						fontSize={[13, 14, 15, 16]}
						fontWeight={400}
					>
						{t("Warehouses.saveAndAddToWarehouse")}
						<ArrowForwardIcon ms={1} boxSize={[4, 4, 5]} />
					</Button>
				</Flex>
				<DocumentAccordion />
				<OrderCreator />
			</Container>
		</Animation>
	)
}

export default WarehouseDocument
