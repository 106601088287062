import { Flex, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useMediaQuery } from "@chakra-ui/react"
import { Children, createRef, memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"

import SwipeableTabs from "./SwipeableTabs"

const TabsFrame = ({ initialTab = 0, tabs, tabStyles, tabPanelsStyles, children, ...props }) => {
	const [isLargerThan992] = useMediaQuery("(min-width: 992px)", {
		ssr: false,
	})
	const [minHeightPanel, setMinHeightPanel] = useState()
	const [clickedTabIndex, setClickedTabIndex] = useState(0)
	const tabsRefs = tabs.map(() => createRef())

	const handleCalculateMinH = useCallback(() => {
		if (!tabsRefs) return

		const minH = tabsRefs.reduce((acc, ref, index) => {
			acc += ref.current?.clientHeight || 0
			acc += index !== 0 && 16
			return acc
		}, 0)

		setMinHeightPanel(minH ?? "auto")
	}, [tabsRefs])

	useEffect(() => {
		window.addEventListener("resize", handleCalculateMinH)

		return () => window.removeEventListener("resize", handleCalculateMinH)
	}, [tabsRefs])

	useLayoutEffect(() => {
		handleCalculateMinH()
	}, [tabsRefs, children, clickedTabIndex, props])

	const getTabPanels = Children.map(children, (child, index) => (
		<TabPanel
			key={index}
			bgColor={"#FFF"}
			minH={{ base: "auto", lg: `${minHeightPanel}px` }}
			w={"100%"}
			p={[4, 6]}
			borderTopRadius={{ base: "none", lg: "4px" }}
			borderBottomRadius={"4px"}
			borderTopStartRadius={{ base: "none", lg: clickedTabIndex === 0 ? 0 : "4px" }}
			boxShadow=' 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			{...props}
		>
			{child}
		</TabPanel>
	))

	const getTabs = useCallback(
		() =>
			tabs?.map((tab, index) => (
				<Tab
					key={index}
					ref={tabsRefs[index]}
					w='100%'
					minH='55px'
					borderRadius='4px'
					mt={index !== 0 && 4}
					border='none'
					bgColor='rgba(255, 255, 255, 0.50)'
					borderColor='rgba(255, 255, 255, 0.50)'
					position={"relative"}
					wordBreak={"break-all"}
					color={tab.color}
					_selected={{
						color: tab.activeColor || "#000",
						bgColor: tab.activeBgColor || "#FFF",
						borderRightRadius: 0,
					}}
					style={{ ...tabStyles }}
					onClick={() => setClickedTabIndex(index)}
				>
					<Flex w='100%' p={{ base: 0, xl: 2 }} alignItems={"center"}>
						{tab.icon &&
							(typeof tab.icon === "string" ? (
								<Image src={tab.icon} alt='Tab icon' boxSize={[5, 6, 7]} objectFit={"contain"} />
							) : (
								tab.icon
							))}
						<Text fontSize={[13, 14, 15, 16]} fontWeight={600} ms={2}>
							{tab.name}
						</Text>
					</Flex>
				</Tab>
			)),
		[tabs, tabsRefs],
	)

	const TabsComponent = useMemo(
		() => (
			<Tabs defaultIndex={initialTab} display={["block", "inline-flex"]} w='100%' mb={[2, 4]}>
				<TabList display={["flex", "block"]} w={["auto", "16%"]} border='none'>
					{getTabs()}
				</TabList>
				<TabPanels style={{ ...tabPanelsStyles }}>{getTabPanels}</TabPanels>
			</Tabs>
		),
		[initialTab, getTabs, getTabPanels, tabPanelsStyles],
	)

	const FinalComponent = isLargerThan992 ? TabsComponent : <SwipeableTabs tabs={tabs}>{getTabPanels}</SwipeableTabs>

	return FinalComponent
}

export default memo(TabsFrame)
