import { memo } from "react"
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Badge,
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	List,
	ListItem,
	Text,
} from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { CopyIcon } from "@chakra-ui/icons"
import { useDispatch } from "react-redux"

import EditIcon from "../../../../../assets/icons/materials/EditIcon"
import PdfIcon from "../../../../../assets/icons/shop/PdfIcon"
import PermissionsChecked from "../../../../common/PermissionsChecked"
import { generatePdf } from "../../../../../actions/modules/orders/shop/shop-actions"

const OrderDetailsAccordion = ({ orderState, orderDetails, disabledForm, setDisabledForm }) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const handleEditOrderDetails = () => {
		navigate(`${location.pathname}/edit`, {
			state: { orderDetails: orderDetails, location: location.pathname },
		})
	}

	const handleGeneratePdf = () => {
		setDisabledForm(true)
		dispatch(generatePdf({ id: orderDetails.id, orderNumber: orderDetails.number })).then(() =>
			setDisabledForm(false),
		)
	}

	const handleCopyOrderDetails = () => {
		const copyPath = location.pathname.split("/")
		navigate(`/${copyPath[1]}/${copyPath[2]}`, {
			state: {
				orderDetails: orderDetails,
				isCopy: true,
				location: location.pathname,
			},
		})
	}

	const getPriority = (priority) => {
		switch (priority) {
			case 1:
				return (
					<Badge fontSize={[10, 11, 12]} color='#FFF' bgColor='gray.600'>
						{t("History.low")}
					</Badge>
				)
			case 2:
				return (
					<Badge fontSize={[10, 11, 12]} color='#FFF' bgColor='blue.600'>
						{t("History.standard")}
					</Badge>
				)
			case 3:
				return (
					<Badge fontSize={[10, 11, 12]} color='#FFF' bgColor='red.600'>
						{t("History.height")}
					</Badge>
				)
			default:
				return (
					<Badge fontSize={[10, 11, 12]} color='#FFF' bgColor='#333333'>
						Empty
					</Badge>
				)
		}
	}

	const getAttachments = orderDetails?.attachments?.map((attachment) => (
		<Flex key={attachment.attachment.id} alignItems='center' my={2}>
			<PdfIcon color='red.500' me={2} boxSize={5} />
			<Text
				fontSize={[13, 14, 15, 16]}
				_hover={{ color: "teal.600" }}
				cursor={"pointer"}
				onClick={() => window.open(attachment.attachment.file, "_blank")}
			>
				{attachment.attachment.name}
			</Text>
		</Flex>
	))

	const getContactPersons = orderDetails?.contact_person.map((contact) => (
		<ListItem key={contact.id}>
			<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
				{contact.name}
			</Text>
		</ListItem>
	))

	return (
		<Accordion allowMultiple boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"} borderRadius='4px' bgColor='#FFF'>
			<AccordionItem p={[3, 3, 4]}>
				<Flex justifyContent='space-between' alignItems='center'>
					<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
						{t("Order.orderDetails")}
					</Text>
					<Flex gap={[1, 3]}>
						<Flex flexWrap={["wrap", "nowrap"]} gap={[2, 3]} justifyContent={"center"}>
							<Button
								display={orderState >= 3 ? "flex" : "none"}
								color='#FFF'
								bgColor={"red.600"}
								_hover={{ backgroundColor: "red.700" }}
								gap={2}
								isDisabled={disabledForm}
								h={["30px", "35px", "40px"]}
								borderRadius='4px'
								onClick={handleGeneratePdf}
							>
								<PdfIcon boxSize={[4, 4, 5]} />
								<Text aria-label='Generate pdf button' fontSize={[13, 14, 15, 16]}>
									{t("Order.generatePdf")}
								</Text>
							</Button>
							<PermissionsChecked
								permissions={"order"}
								checkingRequired={!orderDetails?.my_order}
								allowedPermissions={["copy_order"]}
							>
								<Button
									isDisabled={disabledForm}
									bgColor='#F2F2EF'
									h={["30px", "35px", "40px"]}
									borderRadius='4px'
									onClick={handleCopyOrderDetails}
									aria-label='Copy order details button'
								>
									<CopyIcon me={2} boxSize={[3, 3, 4]} />
									<Text aria-label='Copy order details button' fontSize={[13, 14, 15, 16]}>
										{t("Order.copy")}
									</Text>
								</Button>
							</PermissionsChecked>
						</Flex>
						<PermissionsChecked
							permissions={"order"}
							checkingRequired={!orderDetails?.my_order}
							allowedPermissions={["edit_order"]}
						>
							<Button
								isDisabled={disabledForm}
								display={orderState !== 1 ? "none" : "flex"}
								me={3}
								bgColor='#F2F2EF'
								minW='30px'
								boxSize={["30px", "35px", "40px"]}
								borderRadius='4px'
								onClick={handleEditOrderDetails}
								aria-label='Edit order details'
							>
								<EditIcon boxSize={[5, 5, 6]} />
							</Button>
						</PermissionsChecked>
						<AccordionButton
							minW={"30px"}
							boxSize={["30px", "35px", "40px"]}
							justifyContent='center'
							aria-label='Accordion button'
						>
							<AccordionIcon boxSize={[6, 6, 7]} />
						</AccordionButton>
					</Flex>
				</Flex>
				<AccordionPanel p={0}>
					<Divider my={5} borderWidth='1px' borderColor='#E5E5DF' />
					<Grid mt={6} templateColumns={"repeat(4, 1fr)"}>
						<GridItem>
							<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
								{t("Order.project")}
							</Text>
						</GridItem>
						<GridItem colSpan={3}>
							<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
								{orderDetails?.project}
							</Text>
						</GridItem>
						<GridItem colSpan={4} my={2}>
							<Divider borderColor='#E5E5DF' />
						</GridItem>
						<GridItem>
							<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
								{t("Order.part")}
							</Text>
						</GridItem>
						<GridItem colSpan={3}>
							<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
								{orderDetails?.part}
							</Text>
						</GridItem>
						<GridItem colSpan={4} my={2}>
							<Divider borderColor='#E5E5DF' />
						</GridItem>
						<GridItem>
							<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
								{t("Order.buildingAddress")}
							</Text>
						</GridItem>
						<GridItem colSpan={3}>
							<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
								{orderDetails?.building_address}
							</Text>
						</GridItem>
						<GridItem colSpan={4} my={2}>
							<Divider borderColor='#E5E5DF' />
						</GridItem>
						<GridItem>
							<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
								{t("Order.contactPerson")}
							</Text>
						</GridItem>
						<GridItem colSpan={3}>
							<List>{getContactPersons}</List>
						</GridItem>
						<GridItem colSpan={4} my={2}>
							<Divider borderColor='#E5E5DF' />
						</GridItem>
						<GridItem>
							<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
								{t("Order.orderFor")}
							</Text>
						</GridItem>
						<GridItem colSpan={3}>
							<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
								{orderDetails?.order_for}
							</Text>
						</GridItem>
						<GridItem colSpan={4} my={2}>
							<Divider borderColor='#E5E5DF' />
						</GridItem>
						<GridItem>
							<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
								{t("Order.commentsOptional")}
							</Text>
						</GridItem>
						<GridItem colSpan={3}>
							<Text fontSize={[13, 14, 15, 16]} fontWeight={600}>
								{orderDetails?.comments}
							</Text>
						</GridItem>
						<GridItem colSpan={4} my={2}>
							<Divider borderColor='#E5E5DF' />
						</GridItem>
						<GridItem>
							<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
								{t("Order.deliveryDate")}
							</Text>
						</GridItem>
						<GridItem colSpan={3}>
							<Badge fontSize={[12, 13, 14, 15]} bgColor='#F2F2EF' fontWeight={500}>
								{orderDetails?.delivery_date}
							</Badge>
						</GridItem>
						<GridItem colSpan={4} my={2}>
							<Divider borderColor='#E5E5DF' />
						</GridItem>
						<GridItem>
							<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
								{t("Order.priority")}
							</Text>
						</GridItem>
						<GridItem colSpan={3}>{getPriority(orderDetails?.priority)}</GridItem>
						<GridItem colSpan={4} my={2}>
							<Divider borderColor='#E5E5DF' />
						</GridItem>
						<GridItem>
							<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
								{t("Order.attachments")}
							</Text>
						</GridItem>
						<GridItem colSpan={3}>{getAttachments}</GridItem>
						<GridItem colSpan={4} mt={2}>
							<Divider borderColor='#E5E5DF' />
						</GridItem>
					</Grid>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	)
}

export default memo(OrderDetailsAccordion)
