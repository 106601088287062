import { Box, Image } from "@chakra-ui/react"

import Footer from "../../../components/Footer/Footer"
import Animation from "../../../utils/animations/Routes/AnimatedPage"
import ResetPasswordForm from "../../../components/Auth/RememberPassword/ResetPasswordForm/ResetPasswordForm"

const ResetPassword = () => {
	return (
		<Animation>
			<main>
				<Box bg='#E5E5DF' h={["92vh", "87.3vh", "91vh", "93vh"]}>
					<Image
						w='100%'
						h='100%'
						objectFit={"cover"}
						src={require("../../../assets/images/login/login-background.webp")}
						alt='background-image'
					/>
					<Box
						h={["auto", "auto"]}
						w={["60%", "40%", "50%", "30%", "20%"]}
						position='absolute'
						top={["43%", "43%", "45%", "46%", "50%"]}
						left='50%'
						transform='translate(-50%, -50%)'
						py={["1%", "2%", "2%"]}
						borderRadius='24'
						bgColor='#FFF'
						boxShadow='0px 4px 24px 0px rgba(189, 189, 189, 0.20)'
					>
						<Box display='flex' justifyContent='center'>
							<Image
								boxSize={["10", "10", "10", "auto"]}
								mr={1}
								src={require("../../../assets/images/login/LOGO-Fire-Stop (1) 1.png")}
								alt='FireStop Logo'
							/>
							<Image
								boxSize={["10", "10", "10", "auto"]}
								ml={1}
								src={require("../../../assets/images/login/Poland (PL).png")}
								alt='Polish Flag'
							/>
						</Box>
						<ResetPasswordForm />
					</Box>
				</Box>
			</main>
			<Footer />
		</Animation>
	)
}

export default ResetPassword
