import { Box, Container, Heading, Switch, Text } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import i18next from "i18next"

import { getMaterial } from "../../../../../actions/modules/orders/materials/materials-actions"
import { clearOpenMaterial } from "../../../../../store/modules/orders/materials/materials-slice"
import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import BackForwardButtons from "../../../../../components/common/BackForwardButtons"
import LoadingHOC from "../../../../../components/common/LoadingHOC"
import MaterialForm from "../../../../../components/Modules/Warehouse/Materials/MaterialForm/MaterialForm"
import { updateMaterial } from "../../../../../actions/modules/warehouse/materials/materials-actions"

const EditMaterial = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const { t } = useTranslation("global")
	const [language, setLanguage] = useState(i18next.language)
	const [isEditPreview, setIsEditPreview] = useState(location.state?.isEditPreview)
	const openMaterial = useSelector((state) => state.materials.openMaterial)
	const [material, setMaterial] = useState(null)
	const [isGetted, setIsGetted] = useState(false)

	useEffect(() => {
		if (openMaterial) {
			const materialCopy = {
				...openMaterial,
				newParameter: {
					name_pl: "",
					name_en: "",
					name_nl: "",
					icon: "",
					values: [
						{
							name_pl: "",
							name_en: "",
							name_nl: "",
						},
					],
				},
			}
			setMaterial(materialCopy)
			setIsGetted(true)
		}
	}, [openMaterial])

	useEffect(() => {
		setLanguage(i18next.language)
	}, [i18next.language])

	useEffect(() => {
		dispatch(getMaterial(location.state?.materialId || Number(location.pathname.split("/")[3]), language))

		return () => dispatch(clearOpenMaterial())
	}, [dispatch, language, location.pathname, location.state?.materialId])

	const handleChangeMode = () => {
		setIsEditPreview((prevState) => !prevState)
	}

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='flex' justifyContent='space-between' flexWrap='wrap' mt={2}>
					<Box display='inline-flex' alignItems='center' mb={[2, 2, 2, 0]}>
						<BackForwardButtons />
						<Heading fontSize={["15", "16", "17", "18", "19"]} ms={2}>
							{location.state?.materialName}
						</Heading>
					</Box>
					<Box display={"flex"} h={["30px", "35px", "auto"]} w={["100%", "auto"]} justifyContent={"end"}>
						<Box
							display='flex'
							alignItems='center'
							bgColor='#FFF'
							ms={[2, 2, 3, 4, 5]}
							borderRadius='4px'
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
						>
							<Text mx={3} fontSize={[13, 14, 15, 16]}>
								{t("Materials.editDataMode", { lng: language })}
							</Text>
							<Switch
								aria-label='Edit/Preview switch'
								size={["sm", "sm", "md"]}
								me={3}
								colorScheme='green'
								isChecked={isEditPreview}
								onChange={handleChangeMode}
							/>
						</Box>
					</Box>
				</Box>
				<LoadingHOC isLoading={isGetted}>
					<MaterialForm
						isPatchMethod={true}
						isEditPreview={isEditPreview}
						initialTab={location.state?.initialTab || 0}
						initialValues={material}
						onSubmit={updateMaterial}
					/>
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default EditMaterial
