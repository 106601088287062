import { Box, Container, Divider, Flex, Heading } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import WarehouseNavigationButtons from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseNavigationButtons"
import WarehouseNavigation from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseNavigation"
import WarehouseAddForm from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseAdd/WarehouseAddForm"

const WarehouseAdd = () => {
	const { t } = useTranslation("global")

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Flex justifyContent='space-between' flexWrap={{ base: "wrap", md: "nowrap" }} mt={2} gap={{ md: 2 }}>
					<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 0]}>
						<WarehouseNavigation />
					</Box>
					<WarehouseNavigationButtons />
				</Flex>
				<Divider borderColor='#FFF' borderWidth='2px' my={4} />
				<Heading as={"h3"} fontSize={[13, 14, 15, 16]} fontWeight={600}>
					{t("Warehouses.addToWarehouse")}
				</Heading>
				<WarehouseAddForm />
			</Container>
		</Animation>
	)
}

export default WarehouseAdd
