import {
	Box,
	Button,
	Checkbox,
	Flex,
	Grid,
	GridItem,
	Image,
	Input,
	Text,
	useDisclosure,
	useMediaQuery,
} from "@chakra-ui/react"
import { memo, useCallback, useRef } from "react"
import { useTranslation } from "react-i18next"
import { FastField, Field, useFormikContext } from "formik"
import i18next from "i18next"
import { useLocation, useNavigate } from "react-router-dom"
import imageCompression from "browser-image-compression"
import { AddIcon } from "@chakra-ui/icons"

import { warningToast } from "../../../../../utils/notifications/warningToast"
import TextFieldWithDebounce from "../../../../common/TextFieldWithDebounce"
import LanguageInputs from "../../../../common/LanguageInputs"
import TextAreaWithDebounce from "../../../../common/TextAreaWithDebounce"
import LanguageTextArea from "../../../../common/LanguageTextArea"
import EditIcon from "../../../../../assets/icons/materials/EditIcon"
import BinIcon from "../../../../../assets/icons/materials/BinIcon"
import AddImageIcon from "../../../../../assets/icons/materials/AddImageIcon"
import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import Materials from "../../../../../assets/icons/materials/Materials"
import TabsFrame from "../../../../common/TabsFrame"
import AlertCancelChanges from "../../../../common/AlertCancelChanges"
import NewParameterPanel from "./NewParameterPanel"
import EditMaterialTabPanel from "./EditMaterialTabPanel"
import EditParameterPanel from "./EditParameterPanel"

const EditMaterial = () => {
	const { t } = useTranslation("global")
	const { values, setFieldValue, isSubmitting, setSubmitting, handleSubmit } = useFormikContext()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const location = useLocation()
	const navigate = useNavigate()
	const groupImageInputRef = useRef(null)
	const [isLargerThan640] = useMediaQuery("(min-width: 640px)", {
		ssr: false,
	})

	const compressFile = useCallback(async (file, imageRef, fieldName) => {
		const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(0)
		if (fileSizeInMB >= 100) {
			warningToast(t("Materials.tooBigFile"))
			imageRef.current.value = null
		} else {
			const fileName = file.name
			const options = {
				maxSizeMB: 100,
				maxWidthOrHeight: 1200,
				useWebWorker: true,
			}
			try {
				const compressedFile = await imageCompression(file, options)
				setFieldValue(fieldName, new File([compressedFile], fileName, { type: "image/webp" }))
				imageRef.current.value = null
			} catch (error) {
				console.error(error)
			}
		}
	}, [])

	const handleDragImage = (e) => {
		e.preventDefault()
	}

	const handleDropGroupImage = (e) => {
		e.preventDefault()

		if (e.dataTransfer.files.length === 0) return

		compressFile(e.dataTransfer.files[0], groupImageInputRef, "image")
	}

	const handleAddGroupImage = () => {
		groupImageInputRef.current.click()
	}

	const handleUploadGroupImage = (e) => {
		if (e.target.files.length === 0) return

		compressFile(e.target.files[0], groupImageInputRef, "image")
	}

	const handleRemoveGroupImage = () => {
		groupImageInputRef.current.value = null
		setFieldValue("image", "")
	}

	const getObjectURLFromImg = (icon) => {
		if (!icon || typeof icon === "string") return icon
		return URL.createObjectURL(icon)
	}

	const handleConfirmCancel = () => {
		const navigateLink = location.pathname.split("/")
		navigate(`/${navigateLink[1]}/${navigateLink[2]}`)
	}

	const handleOnSubmit = (e) => {
		e.preventDefault()
		setSubmitting(true)
		setTimeout(() => {
			handleSubmit()
		}, 310)
	}

	const groupInputs = (
		<Grid w='100%' templateColumns={"repeat(8, 1fr)"} mb={!isLargerThan640 && 2} gap={5}>
			<FastField name={`name_${i18next.language}`}>
				{() => (
					<>
						<GridItem minW={["85px", "100px"]}>
							<Text fontSize={[13, 14, 15, 15, 16]} color='#2C7A7B'>
								{t("Materials.groupName")}
							</Text>
						</GridItem>
						<GridItem colSpan={7}>
							<Flex gap={2}>
								<Box w={["150px", "250px"]}>
									<TextFieldWithDebounce
										disabled={isSubmitting}
										name={`name_${i18next.language}`}
										fontSize={[13, 14, 15, 15, 16]}
										h={["30px", "35px", "40px"]}
										maxLength={191}
										type='text'
										placeholder={t("Materials.groupNamePlaceholder")}
									/>
								</Box>
								<LanguageInputs name='name' title={t("Materials.groupName")} maxLength={191} />
							</Flex>
						</GridItem>
					</>
				)}
			</FastField>
			<FastField name={`description_${i18next.language}`}>
				{() => (
					<>
						<GridItem>
							<Text minW={["85px", "100px"]} fontSize={[13, 14, 15, 15, 16]} color='#2C7A7B'>
								{t("Materials.description")}
							</Text>
						</GridItem>
						<GridItem colSpan={7} w={["190px", "350px"]}>
							<Flex me={2} gap={2}>
								<TextAreaWithDebounce
									disabled={isSubmitting}
									maxLength={65535}
									fontSize={[13, 14, 15, 15, 16]}
									name={`description_${i18next.language}`}
									placeholder={t("Materials.descriptionPlaceholder")}
								/>
								<LanguageTextArea
									name='description'
									maxLength={65535}
									title={t("Materials.description")}
								/>
							</Flex>
						</GridItem>
					</>
				)}
			</FastField>
			<FastField key={i18next.language} name='is_out_of_warehouse'>
				{() => (
					<>
						<GridItem>
							<Text
								minW={["85px", "100px"]}
								mt={[0, 0, 0, 0, 2]}
								fontSize={[12, 14, 14, 15, 16]}
								color='#2C7A7B'
							>
								{t("Materials.turnedOffFromWarehouse")}
							</Text>
						</GridItem>
						<GridItem colSpan={7} my='auto'>
							<Field
								aria-label='Is out of warehouse checkbox'
								disabled={isSubmitting}
								as={Checkbox}
								className='materials-checkbox'
								isChecked={values.is_out_of_warehouse}
								type='checkbox'
								name='is_out_of_warehouse'
								colorScheme='teal'
								size='lg'
							/>
						</GridItem>
					</>
				)}
			</FastField>
		</Grid>
	)

	const groupImage = (
		<FastField key={i18next.language} name='image'>
			{({ form }) => (
				<Grid ms={1}>
					<GridItem justifySelf={"center"}>
						<Box
							w={["90px", "110px", "147px", "197px"]}
							h={["140px", "160px", "187px", "208px"]}
							border='1px solid #F2F2EF'
							borderRadius='8px'
							p={[1, 1, 2]}
							onDragOver={handleDragImage}
							onDrop={handleDropGroupImage}
						>
							<Box display={form.values.image ? "block" : "none"} w='100%' h='100%'>
								<Box w='100%' h='75%'>
									<Image
										alt='Group image'
										w='100%'
										h='100%'
										objectFit={"contain"}
										src={getObjectURLFromImg(form.values.image)}
									/>
								</Box>
								<Flex justifyContent={{ base: "center", lg: "end" }} mt={2}>
									<Button
										aria-label='Edit group image button'
										isDisabled={isSubmitting}
										me={2}
										bgColor='#F2F2EF'
										minW='25px'
										boxSize={["25px", "30px", "35px", "40px"]}
										p={0}
										onClick={handleAddGroupImage}
									>
										<EditIcon boxSize={[4, 5, 5, 6]} />
									</Button>
									<Button
										aria-label='Remove group image button'
										isDisabled={isSubmitting}
										bgColor='#F2F2EF'
										p={0}
										minW='25px'
										boxSize={["25px", "30px", "35px", "40px"]}
										onClick={handleRemoveGroupImage}
									>
										<BinIcon boxSize={[4, 5, 5, 6]} color='red.500' />
									</Button>
								</Flex>
							</Box>
							<Button
								isDisabled={isSubmitting}
								display={form.values.image ? "none" : "block"}
								bgColor='#F2F2EF'
								w={["80px", "100px", "130px", "179px"]}
								h={["130px", "150px", "170px", "190px"]}
								onClick={handleAddGroupImage}
							>
								<AddImageIcon boxSize={[4, 5, 6]} />
								<Text
									aria-label='Add group image button'
									whiteSpace='wrap'
									fontSize={[13, 14, 15, 16]}
									fontWeight={400}
									mt={4}
								>
									{t("Materials.addImage")}
								</Text>
							</Button>
						</Box>
						<Input
							display='none'
							name='image'
							type='file'
							accept='image/*'
							ref={groupImageInputRef}
							onChange={handleUploadGroupImage}
						/>
					</GridItem>
				</Grid>
			)}
		</FastField>
	)

	const getTabs = values.parameters?.map((parameter) => ({
		name: parameter[`name_${i18next.language}`],
		icon: parameter.icon ? (
			getObjectURLFromImg(parameter.icon)
		) : (
			<LackImageIcon alt='Tab icon' boxSize={[4, 5, 6]} />
		),
	}))

	getTabs.push({
		name: t("Materials.addNewParameter"),
		icon: <AddIcon alt='Add icon' boxSize={[4, 5, 6]} />,
		color: "#016766",
		activeBgColor: "#319795",
		activeColor: "#FFF",
	})

	getTabs.unshift({
		name: t("Materials.materials"),
		icon: <Materials alt='Tab icon' boxSize={[4, 5, 6]} />,
	})

	const tabs = values.parameters?.length ? (
		<TabsFrame tabs={getTabs}>
			<EditMaterialTabPanel
				compressFile={compressFile}
				handleDragImage={handleDragImage}
				getObjectURLFromImg={getObjectURLFromImg}
			/>
			{values.parameters.map((parameter, index) => (
				<EditParameterPanel
					key={index}
					parameter={parameter}
					parameterId={index}
					handleDragImage={handleDragImage}
					getObjectURLFromImg={getObjectURLFromImg}
				/>
			))}
			<NewParameterPanel handleDragImage={handleDragImage} getObjectURLFromImg={getObjectURLFromImg} />
		</TabsFrame>
	) : (
		<TabsFrame tabs={getTabs}>
			<EditMaterialTabPanel
				compressFile={compressFile}
				handleDragImage={handleDragImage}
				getObjectURLFromImg={getObjectURLFromImg}
			/>
			<NewParameterPanel
				compressFile={compressFile}
				handleDragImage={handleDragImage}
				getObjectURLFromImg={getObjectURLFromImg}
			/>
		</TabsFrame>
	)

	return (
		<Box>
			<Box
				display={isLargerThan640 ? "inline-flex" : "block"}
				w='100%'
				my={5}
				px={4}
				py={6}
				borderRadius='4px'
				boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
				bgColor='#FFF'
			>
				{groupInputs}
				{groupImage}
			</Box>
			{tabs}
			<Flex justifyContent='end' my={6} pb={6}>
				<Button
					isDisabled={isSubmitting}
					bgColor='red.600'
					_hover={{ backgroundColor: "red.700" }}
					colorScheme='red'
					color='#FFF'
					me={2}
					h={["30px", "35px", "40px"]}
					fontSize={[13, 14, 15, 16]}
					onClick={onOpen}
				>
					<Text aria-label='Cancel button'>{t("Materials.cancel")}</Text>
				</Button>
				<Button
					isDisabled={isSubmitting}
					type='submit'
					onClick={handleOnSubmit}
					bgColor='green.600'
					_hover={{ backgroundColor: "green.700" }}
					colorScheme='green'
					color='#FFF'
					h={["30px", "35px", "40px"]}
					fontSize={[13, 14, 15, 16]}
				>
					<Text aria-label='Save button'>{t("Materials.save")}</Text>
				</Button>
			</Flex>
			<AlertCancelChanges isOpen={isOpen} onClose={onClose} handleConfirmCancel={handleConfirmCancel} />
		</Box>
	)
}

export default memo(EditMaterial)
