import { Box, Button, Container, Divider, Flex, Text } from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import { getMaterialsGroups } from "../../../../actions/modules/orders/materials/materials-actions"
import {
	clearMaterialsGroups,
	setSortedMaterialsGroups,
} from "../../../../store/modules/orders/materials/materials-slice"
import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import LoadingHOC from "../../../../components/common/LoadingHOC"
import i18next from "i18next"
import ModuleHeading from "../../../../components/common/ModuleHeading"
import MaterialsGroups from "../../../../components/Modules/Orders/Materials/MaterialsGroups"
import StaticSearch from "../../../../components/common/StaticSearch"

const Materials = () => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const groups = useSelector((state) => state.materials.materialsGroups)
	const [isLoading, setIsLoading] = useState(true)
	const [materials, setMaterials] = useState(null)
	const [showedMaterials, setShowedMaterials] = useState(null)
	const [paginationLength, setPaginationLength] = useState(0)
	const [isDisabled, setIsDisabled] = useState(false)
	const [isSortDescByName, setIsSortDescByName] = useState(true)
	const [isSortDescByTurnOff, setIsSortDescByTurnOff] = useState(true)
	const [searchingTerm, setSearchingTerm] = useState("")

	useEffect(() => setIsLoading(true), [i18next.language])

	useEffect(() => {
		dispatch(getMaterialsGroups()).then(() => setIsLoading(false))
	}, [dispatch, i18next.language])

	useEffect(() => {
		return () => dispatch(clearMaterialsGroups())
	}, [])

	const filteredGroups = useMemo(
		() => groups.filter((group) => group.name.toString().toLowerCase().includes(searchingTerm.toLowerCase())),
		[groups, searchingTerm],
	)

	useEffect(() => {
		if (!groups) return
		if (searchingTerm === "") {
			setShowedMaterials(groups.slice(0, 10))
			setMaterials(groups)
			setPaginationLength(groups.length)
			return
		}
		setShowedMaterials(filteredGroups.slice(0, 10))
		setMaterials(filteredGroups)
		setPaginationLength(filteredGroups.length)
	}, [groups, i18next.language])

	const handleChangePage = (page) => {
		const start = (page - 1) * 10
		const end = start + 10
		const slicedMaterials = materials.slice(start, end)
		setShowedMaterials(slicedMaterials)
	}

	const handleSearching = (filteredArray) => {
		setMaterials(filteredArray)
		setShowedMaterials(filteredArray.slice(0, 10))
		setPaginationLength(filteredArray?.length)
	}

	const handleSortingByName = () => {
		if (isSortDescByName) {
			handleSortByNameDesc()
		} else {
			handleSortByNameAsc()
		}
		handleChangePage(1)
		setIsSortDescByName((prevState) => !prevState)
	}

	const handleSortByNameAsc = useCallback(() => {
		const sortedMaterials = [...materials].sort((a, b) => {
			if (a.name.toString().toLowerCase() < b.name.toString().toLowerCase()) return -1
			if (a.name.toString().toLowerCase() > b.name.toString().toLowerCase()) return 1
			return 0
		})
		const sortedGroups = [...groups].sort((a, b) => {
			if (a.name.toString().toLowerCase() < b.name.toString().toLowerCase()) return -1
			if (a.name.toString().toLowerCase() > b.name.toString().toLowerCase()) return 1
			return 0
		})
		setMaterials(sortedMaterials)
		setShowedMaterials(sortedMaterials.slice(0, 10))
		dispatch(setSortedMaterialsGroups(sortedGroups))
	}, [dispatch, materials, showedMaterials])

	const handleSortByNameDesc = useCallback(() => {
		const sortedMaterials = [...materials].sort((b, a) => {
			if (a.name.toString().toLowerCase() < b.name.toString().toLowerCase()) return -1
			if (a.name.toString().toLowerCase() > b.name.toString().toLowerCase()) return 1
			return 0
		})
		const sortedGroups = [...groups].sort((b, a) => {
			if (a.name.toString().toLowerCase() < b.name.toString().toLowerCase()) return -1
			if (a.name.toString().toLowerCase() > b.name.toString().toLowerCase()) return 1
			return 0
		})
		setMaterials(sortedMaterials)
		setShowedMaterials(sortedMaterials.slice(0, 10))
		dispatch(setSortedMaterialsGroups(sortedGroups))
	}, [dispatch, materials, showedMaterials])

	const handleSortingByTurnOff = () => {
		if (isSortDescByTurnOff) {
			handleSortByTurnOffDesc()
		} else {
			handleSortByTurnOffAsc()
		}
		handleChangePage(1)
		setIsSortDescByTurnOff((prevState) => !prevState)
	}

	const handleSortByTurnOffAsc = useCallback(() => {
		const sortedMaterials = [...materials].sort((a, b) => {
			if (a.is_out_of_warehouse < b.is_out_of_warehouse) return -1
			if (a.is_out_of_warehouse > b.is_out_of_warehouse) return 1
			return 0
		})
		const sortedGroups = [...groups].sort((a, b) => {
			if (a.is_out_of_warehouse < b.is_out_of_warehouse) return -1
			if (a.is_out_of_warehouse > b.is_out_of_warehouse) return 1
			return 0
		})
		setMaterials(sortedMaterials)
		setShowedMaterials(sortedMaterials.slice(0, 10))
		dispatch(setSortedMaterialsGroups(sortedGroups))
	}, [dispatch, materials, showedMaterials])

	const handleSortByTurnOffDesc = useCallback(() => {
		const sortedMaterials = [...materials].sort((b, a) => {
			if (a.is_out_of_warehouse < b.is_out_of_warehouse) return -1
			if (a.is_out_of_warehouse > b.is_out_of_warehouse) return 1
			return 0
		})
		const sortedGroups = [...groups].sort((b, a) => {
			if (a.is_out_of_warehouse < b.is_out_of_warehouse) return -1
			if (a.is_out_of_warehouse > b.is_out_of_warehouse) return 1
			return 0
		})
		setMaterials(sortedMaterials)
		setShowedMaterials(sortedMaterials.slice(0, 10))
		dispatch(setSortedMaterialsGroups(sortedGroups))
	}, [dispatch, materials, showedMaterials])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='flex' justifyContent='space-between' flexWrap='wrap' mt={2}>
					<Box display='inline-flex' alignItems='center' mb={{ base: 2, lg: 0 }}>
						<BackForwardButtons />
						<ModuleHeading heading={t("Materials.heading")} />
					</Box>
					<Box display='inline-flex' h={["35px", "auto"]} alignItems='center' gap={2}>
						<StaticSearch
							fieldsToFilter={["name"]}
							isDisabled={isDisabled}
							searchText={searchingTerm}
							setSearchText={setSearchingTerm}
							handleSetResults={handleSearching}
							originalArray={groups}
						/>
						<Button
							data-testid={"addButton"}
							aria-label='add-button'
							bgColor='#319795'
							color='#FFF'
							colorScheme='teal'
							minW='25px'
							boxSize={["30px", "35px", "40px"]}
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
							onClick={() => navigate(`${location.pathname}/add`)}
						>
							<AddIcon boxSize={[4, 4, 5]} />
						</Button>
					</Box>
				</Box>
				<Divider borderColor='#FFF' borderWidth='2px' my={4} />
				<LoadingHOC isLoading={!isLoading}>
					{materials?.length > 0 && (
						<MaterialsGroups
							materials={showedMaterials}
							isDisabled={isDisabled}
							setIsDisabled={setIsDisabled}
							paginationLength={paginationLength}
							handleChangePage={handleChangePage}
							isSortDescByName={isSortDescByName}
							handleSortingByName={handleSortingByName}
							isSortDescByTurnOff={isSortDescByTurnOff}
							handleSortingByTurnOff={handleSortingByTurnOff}
						/>
					)}
				</LoadingHOC>
				{!isLoading && !materials?.length && (
					<Flex justifyContent='center' alignItems='center' h='80%'>
						<Text fontSize='24px' letterSpacing='4px' fontWeight='600' color='#05050580'>
							{t("History.noData")}
						</Text>
					</Flex>
				)}
			</Container>
		</Animation>
	)
}

export default Materials
