import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Button,
	Divider,
	Flex,
	Heading,
	Text,
} from "@chakra-ui/react"
import { useRef } from "react"
import { useTranslation } from "react-i18next"

import EditIcon from "../../../../../assets/icons/materials/EditIcon"

const DocumentAccordion = () => {
	const { t } = useTranslation("global")
	const accordionBtnRef = useRef()

	const handleExpandRecord = () => {
		if (!accordionBtnRef) return
		accordionBtnRef.current.click()
	}

	const row = (fieldName, children) => (
		<Flex flexDirection={"column"} gap={2}>
			<Flex>
				<Text w={{ base: "200px" }} fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
					{t(`Warehouses.${fieldName}`)}
				</Text>
				{children}
			</Flex>
			<Divider borderColor='#E5E5DF' />
		</Flex>
	)

	return (
		<Accordion
			allowMultiple
			bgColor={"#FFF"}
			boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
			borderRadius={"4px"}
		>
			<AccordionItem cursor={"pointer"} onClick={handleExpandRecord}>
				<Flex p={4} justifyContent={"space-between"} alignItems={"center"}>
					<Heading as={"h3"} fontSize={[13, 14, 15, 16]} fontWeight={600}>
						{t("Warehouses.documentDetails")}
					</Heading>
					<Flex gap={2}>
						<Button
							bgColor={"#F2F2EF"}
							p={0}
							minW='35px'
							boxSize={"35px"}
							onClick={(e) => e.stopPropagation()}
						>
							<EditIcon boxSize={[5, 5, 6]} />
						</Button>
						<AccordionButton ref={accordionBtnRef} p={0} boxSize={["35px"]}>
							<AccordionIcon mx='auto' boxSize={[5, 6, 7]} />
						</AccordionButton>
					</Flex>
				</Flex>
				<AccordionPanel py={0} px={4} onClick={(e) => e.stopPropagation()}>
					<Divider my={5} borderWidth='1px' borderColor='#E5E5DF' />
					<Flex py={4} flexDirection={"column"} gap={3}>
						{row("documentName", <Text fontSize={[13, 14, 15, 16]}>Nazwa dokumentu</Text>)}
						{row("supplier", <Text fontSize={[13, 14, 15, 16]}>Dostawca</Text>)}
						{row("recipient", <Text fontSize={[13, 14, 15, 16]}>Osoba przyjmująca</Text>)}
						{row("comments", <Text fontSize={[13, 14, 15, 16]}>Uwagi (opcjonalnie)</Text>)}
						{row("documentDate", <Text fontSize={[13, 14, 15, 16]}>Data dokumentu</Text>)}
					</Flex>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	)
}

export default DocumentAccordion
