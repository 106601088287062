import { keyframes } from "@emotion/react"

export const magazineCardAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
`
