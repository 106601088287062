import { Box, Link, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

const Footer = () => {
	const [t] = useTranslation("global")

	return (
		<footer>
			<Box display='flex' height={["9vh", "14vh", "9vh", "7vh"]} p={[2, 2, 1]} pb={[3.5, 6, 1]}>
				<Box
					w='100%'
					display='flex'
					justifyContent='space-between'
					px={5}
					alignContent='center'
					flexWrap='wrap'
					borderRadius='24'
					fontSize={["7", "12", "14", "16"]}
					bg='#000'
				>
					<Link color='#FFF' fontWeight='700' isExternal href='https://www.firestopholland.nl'>
						{t("Footer.fireStopLink")}
					</Link>
					<Text color='#F5F5F5' fontWeight='400'>
						{t("Footer.copyright") + " | " + t("Footer.author")}
					</Text>
				</Box>
			</Box>
		</footer>
	)
}

export default Footer
