import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useState } from "react"

import Login from "./layout/Auth/Login/Login"
import LoginRoutes from "./routes/LoginRoutes"
import ProtectedRoutes from "./routes/ProtectedRoutes"
import { auth } from "./actions/auth/auth/auth-actions"
import SendEmail from "./layout/Auth/RememberPassword/SendEmail"
import ResetPassword from "./layout/Auth/RememberPassword/ResetPassword"
import Permissions from "./layout/Permissions/Permissions"
import Home from "./layout/Home/Home"
import Orders from "./layout/Modules/Orders/Orders"
import Warehouse from "./layout/Modules/Warehouse/Warehouse"
import AvailablePermissions from "./layout/Permissions/AvailablePermissions"
import Materials from "./layout/Modules/Orders/Materials/Materials"
import Shop from "./layout/Modules/Orders/Shop/Shop"
import OrderList from "./layout/Modules/Orders/OrderList/OrderList"
import Warehouses from "./layout/Modules/Warehouse/Warehouses/Warehouses"
import History from "./layout/Modules/Orders/History/History"
import HasPermissionsRoutes from "./routes/HasPermissionsRoutes"
import { PERMISSIONS } from "./utils/permissions/permissions"
import HasFunctionalityRoutes from "./routes/HasFunctionalityRoutes"
import { FUNCTIONALITIES } from "./utils/permissions/functionalities"
import WarehouseMaterials from "./layout/Modules/Warehouse/WarehouseMaterials/WarehouseMaterials"
import HasRolesRoutes from "./routes/HasRolesRoutes"
import { ROLES } from "./utils/permissions/roles"
import MaterialDetails from "./layout/Modules/Orders/Materials/EditMaterial/MaterialDetails"
import AddMaterialGroup from "./layout/Modules/Orders/Materials/AddMaterial/AddMaterialGroup"
import Order from "./layout/Modules/Orders/Shop/Order/Order"
import OrdersSettings from "./layout/Modules/Orders/OrdersSettings/OrdersSettings"
import Users from "./layout/Modules/Users/Users"
import Groups from "./layout/Modules/Users/Groups/Groups"
import Employees from "./layout/Modules/Users/Employees/Employees"
import Contractors from "./layout/Modules/Users/Contractors/Contractors"
import UsersSettings from "./layout/Modules/Users/UsersSettings/UsersSettings"
import AddGroup from "./layout/Modules/Users/Groups/AddGroup/AddGroup"
import EditGroup from "./layout/Modules/Users/Groups/EditGroup/EditGroup"
import AddEmployee from "./layout/Modules/Users/Employees/AddEmployee/AddEmployee"
import EditEmployee from "./layout/Modules/Users/Employees/EditEmployee/EditEmployee"
import AddMaterial from "./layout/Modules/Warehouse/WarehouseMaterials/AddMaterial/AddMaterial"
import EditMaterial from "./layout/Modules/Warehouse/WarehouseMaterials/EditMaterial/EditMaterial"
import HasModulePermissionsRoutes from "./routes/HasModulePermissionsRoutes"
import WarehouseManage from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseManage"
import WarehouseDocuments from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseDocuments"
import WarehouseAdd from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseAdd"
import WarehouseRemove from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseRemove"
import WarehouseInventory from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseInventory"
import WarehouseMaterial from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseMaterial"
import WarehouseDocument from "./layout/Modules/Warehouse/Warehouses/Warehouse/WarehouseDocument"
import WarehouseSettings from "./layout/Modules/Warehouse/WarehouseSettings/WarehouseSettings"

function App() {
	const dispatch = useDispatch()
	const [isAuthChecked, setIsAuthChecked] = useState(false)

	if (!isAuthChecked) {
		dispatch(auth()).then(() => {
			setIsAuthChecked(true)
		})
	}

	return (
		isAuthChecked && (
			<Router>
				<Routes>
					<Route element={<LoginRoutes />}>
						<Route path='/' element={<Login />} />
						<Route path='/reset-password' element={<SendEmail />} />
						<Route path='/reset-password/:token' element={<ResetPassword />} />
						<Route path='*' element={<Navigate to='/' />} />
					</Route>
					<Route element={<ProtectedRoutes />}>
						<Route path='/home' element={<Home />} />
						<Route element={<HasRolesRoutes allowedRoles={[ROLES.SUPERUSER, ROLES.ADMIN]} />}>
							<Route path='/permissions' element={<Permissions />} />
						</Route>
						<Route element={<HasRolesRoutes allowedRoles={[ROLES.SUPERUSER]} />}>
							<Route path='/appPermissions' element={<AvailablePermissions />} />
						</Route>
						<Route element={<HasPermissionsRoutes allowedPermissions={[PERMISSIONS.ORDERS]} />}>
							<Route path='/orders'>
								<Route index={true} element={<Orders />} />
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.ORDERS.MATERIALS]}
											moduleUrl={PERMISSIONS.ORDERS}
										/>
									}
								>
									<Route path='materials'>
										<Route index={true} element={<Materials />} />
										<Route path='add' element={<AddMaterialGroup />} />
										<Route path=':materialId' element={<MaterialDetails />} />
									</Route>
								</Route>
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.ORDERS.SHOP]}
											moduleUrl={PERMISSIONS.ORDERS}
										/>
									}
								>
									<Route path='shop'>
										<Route index={true} element={<Shop />} />
										<Route path=':orderId' element={<Order />} />
										<Route path=':orderId/edit' element={<Shop />} />
									</Route>
								</Route>
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.ORDERS.ORDERLIST]}
											moduleUrl={PERMISSIONS.ORDERS}
										/>
									}
								>
									<Route path='order-list'>
										<Route index={true} element={<OrderList />} />
										<Route path=':orderId' element={<Order />} />
										<Route path=':orderId/edit' element={<Shop />} />
									</Route>
								</Route>
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.ORDERS.HISTORY]}
											moduleUrl={PERMISSIONS.ORDERS}
										/>
									}
								>
									<Route path='history' element={<History />} />
								</Route>
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.ORDERS.SETTINGS]}
											moduleUrl={PERMISSIONS.ORDERS}
										/>
									}
								>
									<Route path='settings' element={<OrdersSettings />} />
								</Route>
							</Route>
						</Route>
						<Route element={<HasPermissionsRoutes allowedPermissions={[PERMISSIONS.USERS]} />}>
							<Route path='/users'>
								<Route index={true} element={<Users />} />
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.USERS.GROUPS]}
											moduleUrl={PERMISSIONS.USERS}
										/>
									}
								>
									<Route path='groups'>
										<Route index={true} element={<Groups />} />
										<Route
											element={
												<HasModulePermissionsRoutes
													permissions={"user"}
													allowedPermissions={["add_group"]}
												/>
											}
										>
											<Route path='add' element={<AddGroup />} />
										</Route>
										<Route
											element={
												<HasModulePermissionsRoutes
													permissions={"user"}
													allowedPermissions={["edit_groups"]}
												/>
											}
										>
											<Route path='edit/:groupId' element={<EditGroup />} />
										</Route>
									</Route>
								</Route>
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.USERS.EMPLOYEES]}
											moduleUrl={PERMISSIONS.USERS}
										/>
									}
								>
									<Route path='employees'>
										<Route index={true} element={<Employees />} />
										<Route
											element={
												<HasModulePermissionsRoutes
													permissions={"user"}
													allowedPermissions={["add_user"]}
												/>
											}
										>
											<Route path='add' element={<AddEmployee />} />
										</Route>
										<Route
											element={
												<HasModulePermissionsRoutes
													permissions={"user"}
													allowedPermissions={["edit_users"]}
												/>
											}
										>
											<Route path='edit/:employeeId' element={<EditEmployee />} />
										</Route>
									</Route>
								</Route>
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.USERS.CONTRACTORS]}
											moduleUrl={PERMISSIONS.USERS}
										/>
									}
								>
									<Route path='contractors'>
										<Route index={true} element={<Contractors />} />
									</Route>
								</Route>
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.USERS.SETTINGS]}
											moduleUrl={PERMISSIONS.USERS}
										/>
									}
								>
									<Route path='settings' element={<UsersSettings />} />
								</Route>
							</Route>
						</Route>
						<Route element={<HasPermissionsRoutes allowedPermissions={[PERMISSIONS.WAREHOUSE]} />}>
							<Route path='/warehouse'>
								<Route index={true} element={<Warehouse />} />
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.WAREHOUSE.MATERIALS]}
											moduleUrl={PERMISSIONS.WAREHOUSE}
										/>
									}
								>
									<Route path='materials' element={<WarehouseMaterials />} />
									<Route path='materials'>
										<Route index={true} element={<WarehouseMaterials />} />
										<Route path='add' element={<AddMaterial />} />
										<Route path=':materialId' element={<EditMaterial />} />
									</Route>
								</Route>
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.WAREHOUSE.WAREHOUSES]}
											moduleUrl={PERMISSIONS.WAREHOUSE}
										/>
									}
								>
									<Route path='warehouses'>
										<Route index={true} element={<Warehouses />} />
										<Route path=':warehouseId' element={<WarehouseManage />} />
										<Route path=':warehouseId/:materialId' element={<WarehouseMaterial />} />
										<Route path=':warehouseId/documents' element={<WarehouseDocuments />} />
										<Route
											path=':warehouseId/documents/:documentId'
											element={<WarehouseDocument />}
										/>
										<Route path=':warehouseId/add' element={<WarehouseAdd />} />
										<Route path=':warehouseId/remove' element={<WarehouseRemove />} />
										<Route path=':warehouseId/inventory' element={<WarehouseInventory />} />
									</Route>
								</Route>
								<Route
									element={
										<HasFunctionalityRoutes
											allowedFunctionality={[FUNCTIONALITIES.WAREHOUSE.SETTINGS]}
											moduleUrl={PERMISSIONS.WAREHOUSE}
										/>
									}
								>
									<Route path='settings' element={<WarehouseSettings />} />
								</Route>
							</Route>
						</Route>
						<Route path='*' element={<Navigate to='/home' />} />
					</Route>
				</Routes>
			</Router>
		)
	)
}

export default App
