import { Flex, Heading } from "@chakra-ui/react"
import { useSelector } from "react-redux"
import { useState } from "react"

import BackForwardButtons from "../../../common/BackForwardButtons"
import ExampleIcon from "../../../../assets/icons/header/ExampleIcon"

const WarehouseNavigation = () => {
	const modules = useSelector((state) => state.auth.modules)
	const [currentModule] = useState(modules.find((module) => location.pathname.includes(module.url)))

	return (
		<Flex alignItems={"center"} gap={2}>
			<BackForwardButtons />
			<Flex
				alignItems='center'
				justifyContent={"center"}
				bgColor={currentModule.primary_color || "#FFF"}
				color={currentModule.font_color || "#333"}
				py={2}
				px={3}
				borderRadius='24px'
				boxShadow={"0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)"}
				gap={1}
			>
				<ExampleIcon boxSize={5} />
				<Heading display='flex' whiteSpace={"nowrap"} fontSize={["14", "14", "16"]} fontWeight={600}>
					Nazwa magazynu
				</Heading>
			</Flex>
		</Flex>
	)
}

export default WarehouseNavigation
