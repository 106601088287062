import { Box, Container, Divider, Flex } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import i18next from "i18next"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import WarehouseNavigationButtons from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseNavigationButtons"
import GroupsList from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/GroupsList"
import ActionsBar from "../../../../../components/Modules/Warehouse/Warehouses/Warehouse/ActionsBar"
import { getMaterialGroups } from "../../../../../actions/modules/warehouse/warehouses/warehouses-actions"
import { clearMaterialGroups } from "../../../../../store/modules/warehouse/warehouses/warehouses-slice"
import LoadingHOC from "../../../../../components/common/LoadingHOC"
import WarehouseNavigation from "../../../../../components/Modules/Warehouse/Warehouses/WarehouseNavigation"
import NoData from "../../../../../components/common/NoData"

const WarehouseManage = () => {
	const dispatch = useDispatch()
	const groupsList = useSelector((state) => state.warehouses.materialGroups)
	const [groups, setGroups] = useState()
	const [groupsCopy, setGroupsCopy] = useState()
	const [recordsPerPage, setRecordsPerPage] = useState()
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		if (!groupsCopy) return
		setData(groupsCopy)
	}, [recordsPerPage])

	useEffect(() => {
		setIsLoading(true)
		dispatch(getMaterialGroups()).then((data) => {
			setData(data.payload)
			setIsLoading(false)
		})

		return () => dispatch(clearMaterialGroups())
	}, [i18next.language])

	const getMaxLength = () => {
		if (!groupsCopy) return

		return getConcatenatedMaterials(groupsCopy).length
	}

	const getConcatenatedMaterials = (array) => {
		let materials = []
		array.forEach((group) => group.materials.forEach((material) => materials.push(material)))
		return materials
	}

	const getGroupsFromConcatenatedMaterial = useCallback(
		(array) => {
			let prevGroupId
			let groups = []
			array.forEach((item) => {
				if (prevGroupId === item.group_id) {
					groups[groups.length - 1].materials = [...groups[groups.length - 1].materials, item]
					prevGroupId = item.group_id
					return
				}

				prevGroupId = item.group_id
				const group = { ...groupsList?.find((group) => group.id === item.group_id) }
				group.materials = [item]
				groups.push(group)
			})

			return groups
		},
		[groupsList],
	)

	const setData = (initData) => {
		const materials = getConcatenatedMaterials(initData).slice(0, recordsPerPage)
		const groups = getGroupsFromConcatenatedMaterial(materials)
		setGroups(groups)
		setGroupsCopy(initData)
	}

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Flex justifyContent='space-between' flexWrap={{ base: "wrap", md: "nowrap" }} mt={2} gap={{ md: 2 }}>
					<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 0]}>
						<WarehouseNavigation />
					</Box>
					<WarehouseNavigationButtons />
				</Flex>
				<Divider borderColor='#FFF' borderWidth='2px' my={4} />
				<ActionsBar
					groupsList={groupsList}
					setData={setData}
					setRecordsPerPage={setRecordsPerPage}
					maxLength={getMaxLength()}
				/>
				<LoadingHOC isLoading={!isLoading}>
					<Box my={10} pb={5}>
						{groups?.length ? (
							<GroupsList
								groupsList={groupsList}
								groups={groups}
								setGroups={setGroups}
								groupsCopy={groupsCopy}
								setGroupsCopy={setGroupsCopy}
								recordsPerPage={recordsPerPage}
								getConcatenatedMaterials={getConcatenatedMaterials}
								getGroupsFromConcatenatedMaterial={getGroupsFromConcatenatedMaterial}
							/>
						) : (
							<NoData />
						)}
					</Box>
				</LoadingHOC>
			</Container>
		</Animation>
	)
}

export default WarehouseManage
