import React, { memo } from "react"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { Button } from "@chakra-ui/react"

const ExcelButton = ({ jsonData, fileName }) => {
	const exportToExcel = () => {
		const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
		const fileExtension = ".xlsx"
		const ws = XLSX.utils.json_to_sheet(jsonData)
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
		const data = new Blob([excelBuffer], { type: fileType })
		FileSaver.saveAs(data, fileName + fileExtension)
	}

	return (
		<Button
			fontSize={[13, 14, 15, 16]}
			fontWeight={"400"}
			h={["25px", "35px", "40px"]}
			bgColor={"#FFF"}
			borderRadius={"4px"}
			boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			onClick={exportToExcel}
		>
			Excel
		</Button>
	)
}

export default memo(ExcelButton)
