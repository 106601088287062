import { Button, Flex } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import ExcelButton from "./ExcelButton"
import PDFButton from "./PDFButton"
import { memo } from "react"

const WarehouseActionButtons = () => {
	const { t } = useTranslation("global")

	return (
		<Flex gap={2} w={["100%", "auto"]} flexWrap={["wrap", "wrap", "nowrap"]} justifyContent={"end"}>
			<ExcelButton
				jsonData={[
					{
						grupa: "Grupa 1",
						produkt: "a",
					},
					{
						grupa: "Grupa 2",
						produkt: "b",
					},
				]}
				fileName={"example"}
			/>
			<PDFButton />
			<Button
				fontSize={[13, 14, 15, 16]}
				fontWeight={"400"}
				h={["25px", "35px", "40px"]}
				bgColor={"#FFF"}
				borderRadius={"4px"}
				boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			>
				{t("Warehouses.copy")}
			</Button>
			<Button
				fontSize={[13, 14, 15, 16]}
				fontWeight={"400"}
				h={["25px", "35px", "40px"]}
				bgColor={"#FFF"}
				borderRadius={"4px"}
				boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
			>
				{t("Warehouses.print")}
			</Button>
		</Flex>
	)
}

export default memo(WarehouseActionButtons)
