import { Flex, Text, useDisclosure } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"

import FormikForm from "../../../../common/FormikForm"
import WhiteBackground from "../../../../common/WhiteBackground"
import TextFieldWithDebounce from "../../../../common/TextFieldWithDebounce"
import SelectWithSearching from "../../../../common/SelectWithSearching"
import TextAreaWithDebounce from "../../../../common/TextAreaWithDebounce"
import TextField from "../../../../common/TextField"
import ActionButtons from "./ActionButtons"
import AlertCancelChanges from "../../../../common/AlertCancelChanges"

const WarehouseAddForm = () => {
	const { t } = useTranslation("global")
	const { isOpen, onOpen, onClose } = useDisclosure()
	const navigate = useNavigate()
	const location = useLocation()

	const handleConfirmCancel = () => {
		const splittedPath = location.pathname.split("/")
		navigate(`/${splittedPath[1]}/${splittedPath[2]}/${splittedPath[3]}`)
	}

	const row = (textProp, field) => (
		<Flex
			alignItems='center'
			gap={[1, 5, 7, 10, 16]}
			justifyContent={"space-between"}
			flexWrap={{ base: "wrap", sm: "nowrap" }}
		>
			<Text
				w={{ base: "100%", sm: "150px", md: "175px", lg: "200px" }}
				minW={{ base: "100%", sm: "150px", md: "175px", lg: "200px" }}
				fontSize={[13, 14, 15, 15, 16]}
				color='#2C7A7B'
				wordBreak={"break-word"}
			>
				{t(`Warehouses.${textProp}`)}
			</Text>
			{field}
		</Flex>
	)

	return (
		<FormikForm
			shouldNavigate={false}
			initialValues={{
				document_name: "",
				supplier: "",
				recipient: "",
				comments: "",
				document_date: "",
			}}
			validationSchema={Yup.object({
				document_name: Yup.string().required(t("Warehouses.documentNameRequired")),
			})}
			// onSubmit={setUsersRoles}
		>
			<WhiteBackground my={5}>
				<Flex flexDirection={"column"} gap={2}>
					{row(
						"documentName",
						<TextFieldWithDebounce
							mt={["1", "1", "1", "2"]}
							name='document_name'
							fontSize={[13, 14, 15, 15, 16]}
							h={["30px", "35px", "40px"]}
							type='text'
							placeholder={t("Warehouses.addDocumentName")}
						/>,
					)}
					{row(
						"supplier",
						<SelectWithSearching
							name='supplier'
							mt={["1", "1", "1", "2"]}
							placeholder={t("Warehouses.selectSupplier")}
							fontSize={[13, 14, 15, 15, 16]}
							values={[
								{ id: 1, name: "ASD" },
								{ id: 2, name: "222" },
								{ id: 3, name: "ABBA" },
							]}
						/>,
					)}
					{row(
						"recipient",
						<Text w='100%' fontSize={[13, 14, 15, 15, 16]} color='gray.500'>
							Imię i nazwisko osoby zalogowanej
						</Text>,
					)}
					{row(
						"comments",
						<TextAreaWithDebounce
							mt={["1", "1", "1", "2"]}
							name='comments'
							fontSize={[13, 14, 15, 15, 16]}
							placeholder={t("Warehouses.addComments")}
						/>,
					)}
					{row(
						"documentDate",
						<TextField
							mt={["1", "1", "1", "2"]}
							cursor='pointer'
							fontSize={[13, 14, 15, 16]}
							h={["30px", "35px", "40px"]}
							name='document_date'
							type='date'
						/>,
					)}
				</Flex>
			</WhiteBackground>
			<ActionButtons onOpen={onOpen} />
			<AlertCancelChanges isOpen={isOpen} onClose={onClose} handleConfirmCancel={handleConfirmCancel} />
		</FormikForm>
	)
}

export default WarehouseAddForm
