import {
	Box,
	Button,
	Checkbox,
	Editable,
	EditableInput,
	EditablePreview,
	Flex,
	Grid,
	GridItem,
	Image,
	Input,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import React, { memo, useCallback, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import SearchBar from "../../../../common/SearchBar"
import BsSortDown from "../../../../../assets/icons/permissions/BsSortDown"
import CommentIcon from "../../../../../assets/icons/shop/CommentIcon"
import MaterialComment from "../MaterialComment/MaterialComment"
import CommentIconWithMessage from "../../../../../assets/icons/shop/CommentIconWithMessage"
import LackImageIcon from "../../../../../assets/icons/materials/LackImageIcon"
import BsSortUp from "../../../../../assets/icons/permissions/BsSortUp"
import EditableControls from "../../../../common/EditableControls"
import { changeOrderPackedCount } from "../../../../../actions/modules/orders/shop/shop-actions"
import ExpandedIcon from "../../../../../assets/icons/shop/ExpandedIcon"
import PermissionsChecked from "../../../../common/PermissionsChecked"
import LoadingHOC from "../../../../common/LoadingHOC"

const OrderedMaterials = ({
	unpackingSearchText,
	setUnpackingSearchText,
	isMyOrder,
	isDescSort,
	handleSorting,
	orders,
	isDisabled,
	setDisabledForm,
	originalArray,
	orderState,
	setOrders,
	handlePacking,
	searchBarBreakpoints,
	isOrdersGetted,
}) => {
	const { t } = useTranslation("global")
	const dispatch = useDispatch()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [isListFormat, setIsListFormat] = useState(true)
	const [currentProductId, setCurrentProductId] = useState(0)
	const packedCountRef = useRef()

	const handleKeyDown = (event) => {
		if (event.key === "." || event.key === "," || event.key === "-" || event.key === "+" || event.key === "/") {
			event.preventDefault()
		}
	}

	const handlePackedCountChange = useCallback(
		(order, value) => {
			const originalOrder = originalArray.find((item) => item.id === order.id)
			// eslint-disable-next-line eqeqeq
			if (packedCountRef.current.textContent == originalOrder.count_packed) {
				packedCountRef.current.textContent = originalOrder.count_packed
			} else {
				setDisabledForm(true)
				dispatch(
					changeOrderPackedCount({
						id: order.id,
						count: Number(value),
						originalArray,
						setOrders,
					}),
				).then(() => setDisabledForm(false))
			}
		},
		[dispatch, originalArray, setDisabledForm, setOrders],
	)

	const handleChangeListFormat = () => {
		setIsListFormat((prevState) => !prevState)
	}

	const handleOpenComment = useCallback(
		(orderId) => {
			setCurrentProductId(orderId)
			onOpen()
		},
		[onOpen],
	)

	const handleCountChange = useCallback(
		(id, value) => {
			const newOrders = orders?.map((order) => {
				if (order.id === id) {
					return {
						...order,
						count_packed: Number(value),
					}
				}
				return order
			})

			setOrders(newOrders)
		},
		[orders, setOrders],
	)

	const orderedMaterials = useMemo(
		() =>
			orders?.map((order, orderId) => (
				<GridItem
					key={orderId}
					border='1px solid var(--teal-500, #319795)'
					boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
					borderRadius='4px'
					h='100%'
				>
					<Flex display='inline-flex' h={isListFormat ? "100%" : "80%"} w='100%'>
						<PermissionsChecked
							permissions={"order"}
							checkingRequired={!isMyOrder}
							allowedPermissions={["pack_order"]}
						>
							<Box
								display={isListFormat && orderState === 3 ? "flex" : "none"}
								w={["20px", "25px", "30px"]}
								bgColor={"rgba(49, 151, 149, 0.20)"}
								justifyContent='center'
								alignItems='center'
							>
								<Checkbox
									isDisabled={isDisabled}
									aria-label='Packing checkbox'
									size={["sm", "md", "md"]}
									borderColor={"var(--teal-500, #319795)"}
									isChecked={false}
									onChange={() => handlePacking(order.id)}
									colorScheme='teal'
								/>
							</Box>
						</PermissionsChecked>
						<Box px={[1, 3]} pt={3} pb={2} h='80%' w='100%'>
							<Flex alignItems='start' justifyContent='space-between'>
								<Flex>
									{!order.is_custom &&
										(order.image ? (
											<Image
												w={["40px", "50px", "70px"]}
												h={["50px", "60px", "80px"]}
												src={order.image}
												alt='materialImage'
											/>
										) : (
											<LackImageIcon
												w={["40px", "50px", "70px"]}
												h={["50px", "60px", "80px"]}
												alt='lack of image'
											/>
										))}
									<Flex
										display={isListFormat ? ["block", "block", "flex"] : "block"}
										flexWrap={"nowrap"}
										gap={isListFormat && [4, 5, 14, 28]}
										ms={isListFormat ? [2, 5, 7] : [2, 2, 3]}
										alignItems={isListFormat && "center"}
									>
										<Flex
											minW={order.is_custom && ["50px", "50px", "70px", "315px"]}
											maxW={["190px", "auto"]}
											alignItems={"center"}
										>
											{order.is_custom ? (
												<>
													<Text fontSize={[13, 14, 15, 16, 17]} color='#2C7A7B' me={3}>
														{t("Order.description")}:{" "}
													</Text>
													<Text fontSize={[13, 14, 15, 16, 17]} maxW='50%' fontWeight={600}>
														{order.description}
													</Text>
												</>
											) : (
												<>
													<Text fontSize={[13, 14, 15, 16, 17]} color='#2C7A7B' me={3}>
														{t("Order.material")}:{" "}
													</Text>
													<Text
														maxW={
															isListFormat
																? ["60px", "100px", "45px", "70px", "150px"]
																: ["60px", "100px", "120px", "150px", "200px"]
														}
														fontSize={[13, 14, 15, 16, 17]}
														fontWeight={600}
													>
														{order.name}
													</Text>
												</>
											)}
										</Flex>
										<Flex display={order.is_custom ? "none" : "flex"}>
											<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B' me={3}>
												{t("Order.group")}:{" "}
											</Text>
											<Text
												fontSize={[13, 14, 15, 16]}
												maxW={
													isListFormat
														? ["60px", "100px", "45px", "70px", "150px"]
														: ["60px", "100px", "120px", "150px", "200px"]
												}
											>
												{order.group_name}
											</Text>
										</Flex>
										<Flex
											flexDirection={
												isListFormat && order.is_custom
													? ["column", "row"]
													: isListFormat
													  ? ["column", "column", "column", "column", "row"]
													  : "column"
											}
											gap={
												isListFormat && order.is_custom
													? [0, 5, 14, 28]
													: isListFormat && [0, 0, 0, 0, 28]
											}
											alignItems={
												isListFormat && order.is_custom
													? ["start", "center"]
													: isListFormat && ["start", "start", "start", "start", "center"]
											}
										>
											<Flex>
												<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
													{t("Order.ordered")}:{" "}
												</Text>
												<Text fontSize={[13, 14, 15, 16]} ms={2} wordBreak={"break-word"}>
													{order.count}
												</Text>
											</Flex>
											<Flex display={orderState === 3 ? "flex" : "none"}>
												<Editable
													display={"inline-flex"}
													justifyContent={"start"}
													alignItems='center'
													w='100%'
													me={[0, 0, 3]}
													value={order.count_packed}
													onChange={(e) => handleCountChange(order.id, e)}
													onSubmit={(e) => handlePackedCountChange(order, e)}
													fontSize='md'
													isPreviewFocusable={false}
												>
													<Text
														fontSize={[13, 14, 15, 16]}
														whiteSpace='nowrap'
														color='#2C7A7B'
														me={3}
													>
														{t("Order.packedCount")}:
													</Text>
													<EditablePreview
														wordBreak={"break-word"}
														fontSize={[13, 14, 15, 16]}
														ref={packedCountRef}
													/>
													<Input
														h={["25px", "25px", "30px"]}
														fontSize={[13, 14, 15, 16]}
														type='number'
														onKeyDown={(e) => handleKeyDown(e)}
														onPaste={(e) => e.preventDefault()}
														as={EditableInput}
													/>
													<PermissionsChecked
														permissions={"order"}
														checkingRequired={!isMyOrder}
														allowedPermissions={["edit_order"]}
													>
														<EditableControls isDisabled={isDisabled} />
													</PermissionsChecked>
												</Editable>
											</Flex>
										</Flex>
									</Flex>
								</Flex>
								<Flex>
									{orderState > 2 && (
										<Flex justifyContent='end'>
											<Button
												isDisabled={isDisabled}
												aria-label='Open comment button'
												bgColor='#FFF'
												_hover={{ backgroundColor: "#F2F2EF" }}
												p={0}
												boxSize={["30px", "35px", "40px"]}
												onClick={() => handleOpenComment(orderId)}
											>
												{order.comment ? (
													<CommentIconWithMessage boxSize={["22px", "22px", "26px"]} />
												) : (
													<CommentIcon boxSize={[5, 5, 6]} />
												)}
											</Button>
										</Flex>
									)}
								</Flex>
							</Flex>
							<Flex justifyContent='space-between'>
								<Box>
									<Flex display={!order.is_custom && order.description ? "flex" : "none"}>
										<Text fontSize={[13, 14, 15, 16]} color='#2C7A7B'>
											{t("Order.description")}:
										</Text>
										<Text
											maxW={["100px", "150px", "200px", "300px", "400px"]}
											fontSize={[13, 14, 15, 16]}
											ms={2}
										>
											{order.description}
										</Text>
									</Flex>
									{order.parameters?.map((parameter, index) => (
										<Flex key={index} my={1}>
											<Text
												maxW={
													isListFormat
														? ["60px", "100px", "45px", "70px", "150px"]
														: ["60px", "100px", "120px", "150px", "200px"]
												}
												fontSize={[13, 14, 15, 16]}
												color='#2C7A7B'
											>
												{parameter.name}:
											</Text>
											{parameter.values.map((value, valueId) => (
												<Text
													maxW={
														isListFormat
															? ["60px", "100px", "45px", "70px", "150px"]
															: ["60px", "100px", "120px", "150px", "200px"]
													}
													fontSize={[13, 14, 15, 16]}
													key={valueId}
													bgColor='#F2F2EF'
													borderRadius='4px'
													px={1}
													ms={2}
												>
													{value.name}
												</Text>
											))}
										</Flex>
									))}
								</Box>
							</Flex>
						</Box>
					</Flex>
					{orderState === 3 && (
						<PermissionsChecked
							permissions={"order"}
							checkingRequired={!isMyOrder}
							allowedPermissions={["pack_order"]}
						>
							<Flex h='20%' alignItems='end'>
								<Flex
									display={isListFormat ? "none" : "flex"}
									h={["25px", "30px", "35px"]}
									w='100%'
									bgColor={"rgba(49, 151, 149, 0.20)"}
									justifyContent='center'
									alignItems='center'
								>
									<Checkbox
										isDisabled={isDisabled}
										aria-label='Packing checkbox'
										size={["sm", "md", "md"]}
										borderColor={"var(--teal-500, #319795)"}
										isChecked={false}
										onChange={() => handlePacking(order.id)}
										colorScheme='teal'
									/>
									<Text fontSize={[13, 14, 15, 16]} display='inline' color='#2C7A7B' ms={3}>
										{t("Order.packed")}
									</Text>
								</Flex>
							</Flex>
						</PermissionsChecked>
					)}
				</GridItem>
			)),
		[
			isListFormat,
			isMyOrder,
			orderState,
			isDisabled,
			t,
			orders,
			handlePacking,
			handleCountChange,
			handlePackedCountChange,
			handleOpenComment,
		],
	)

	return (
		<Box boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"} borderRadius='4px' bgColor='#FFF' p={4} mb={[4, 4, 5]}>
			<Flex display={["block", "flex"]} justifyContent={["start", "space-between"]} alignItems='center' mb={4}>
				<Flex>
					<Text maxW={["75px", "75px", "100%"]} fontSize={[13, 14, 15, 16]} fontWeight={600} me={2}>
						{t("Order.orderedProducts")}
					</Text>
					<Text fontSize={[13, 14, 15, 16]} color='#2B807E'>
						({originalArray.length})
					</Text>
				</Flex>
				<Flex justifyContent='end' mt={[2, 0, 0]} gap={2}>
					<SearchBar
						fieldsToFilter={["name", "group_name"]}
						isDisabled={isDisabled}
						searchText={unpackingSearchText}
						setSearchText={setUnpackingSearchText}
						originalArray={originalArray}
						handleSetResults={setOrders}
						buttonStyles={{
							height: searchBarBreakpoints,
							width: searchBarBreakpoints,
						}}
						h={searchBarBreakpoints}
					/>
					<PermissionsChecked
						permissions={"order"}
						checkingRequired={!isMyOrder}
						allowedPermissions={["edit_order"]}
					>
						<Button
							isDisabled={isDisabled || !originalArray.length}
							aria-label='Sorting button'
							bgColor='#FFF'
							p={0}
							minW='30px'
							boxSize={["30px", "30px", "35px"]}
							border='1px solid #D4D4CD'
							borderRadius='4px'
							onClick={handleSorting}
						>
							{isDescSort ? <BsSortDown /> : <BsSortUp />}
						</Button>
					</PermissionsChecked>
					<Button
						bgColor='#FFF'
						aria-label='Change list format button'
						p={0}
						minW='30px'
						boxSize={["30px", "30px", "35px"]}
						border='1px solid #D4D4CD'
						borderRadius='4px'
						onClick={handleChangeListFormat}
					>
						<ExpandedIcon boxSize={[4, 4, 5]} />
					</Button>
				</Flex>
			</Flex>
			<Grid
				templateColumns={
					isListFormat
						? "repeat(1, 1fr)"
						: ["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]
				}
				gap={[2, 3, 4]}
			>
				{originalArray?.length > 0 && <LoadingHOC isLoading={isOrdersGetted}>{orderedMaterials}</LoadingHOC>}
			</Grid>
			{isOpen && (
				<MaterialComment
					isMyOrder={isMyOrder}
					setDisabledForm={setDisabledForm}
					orderId={orders[currentProductId].id}
					isOpen={isOpen}
					onClose={onClose}
					comment={orders[currentProductId].comment}
				/>
			)}
		</Box>
	)
}

export default memo(OrderedMaterials)
