import { Box, Container, Heading } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import i18next from "i18next"

import Animation from "../../../../../utils/animations/Routes/AnimatedPage"
import BackForwardButtons from "../../../../../components/common/BackForwardButtons"
import MaterialForm from "../../../../../components/Modules/Warehouse/Materials/MaterialForm/MaterialForm"
import { createMaterialsGroup } from "../../../../../actions/modules/warehouse/materials/materials-actions"

const AddMaterial = () => {
	const { t } = useTranslation("global")
	const [language, setLanguage] = useState(i18next.language)
	const [initialValues] = useState({
		name_pl: "",
		name_en: "",
		name_nl: "",
		description_pl: "",
		description_en: "",
		description_nl: "",
		parameters: [],
		image: "",
		is_out_of_warehouse: false,
		materials: [],
		newParameter: {
			name_pl: "",
			name_en: "",
			name_nl: "",
			icon: "",
			values: [
				{
					name_pl: "",
					name_en: "",
					name_nl: "",
				},
			],
		},
	})

	useEffect(() => {
		setLanguage(i18next.language)
	}, [i18next.language])

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box display='flex' justifyContent='space-between' flexWrap='wrap' mt={2}>
					<Box display='inline-flex' alignItems='center' mb={[2, 2, 2, 0]}>
						<BackForwardButtons />
						<Heading fontSize={["15", "16", "17", "18", "19"]} ms={2}>
							{t("Materials.createNewGroup", { lng: language })}
						</Heading>
					</Box>
				</Box>
				<MaterialForm initialValues={initialValues} onSubmit={createMaterialsGroup} />
			</Container>
		</Animation>
	)
}

export default AddMaterial
