import { Flex } from "@chakra-ui/react"
import { memo, useEffect, useState } from "react"
import i18next from "i18next"

import GroupFilterSelect from "./GroupFilterSelect"
import StaticSearch from "../../../../common/StaticSearch"
import WarehouseActionButtons from "./WarehouseActionButtons"
import QuantitySelect from "./QuantitySelect"

const ActionsBar = ({ groupsList, setRecordsPerPage, maxLength, setData }) => {
	const [searchingTerm, setSearchingTerm] = useState("")
	const [filterGroupValue, setFilterGroupValue] = useState("")

	useEffect(() => {
		setSearchingTerm("")
		setFilterGroupValue("")
	}, [i18next.language])

	const handleSetResults = (results) => {
		setData(results)
	}

	return (
		<Flex w='100%' justifyContent={"end"} gap={2} flexWrap={{ base: "wrap", xl: "nowrap" }}>
			<QuantitySelect setRecordsPerPage={setRecordsPerPage} maxLength={maxLength} />
			<GroupFilterSelect
				arrayToFilter={groupsList}
				searchingTerm={searchingTerm}
				handleSetResults={handleSetResults}
				filterGroupValue={filterGroupValue}
				setFilterGroupValue={setFilterGroupValue}
			/>
			<Flex>
				<StaticSearch
					fieldsToFilter={["name"]}
					isDisabled={!groupsList?.length}
					searchText={searchingTerm}
					setSearchText={setSearchingTerm}
					handleSetResults={handleSetResults}
					originalArray={groupsList}
					dependencies={[{ name: "name", value: filterGroupValue }]}
				/>
			</Flex>
			<WarehouseActionButtons />
		</Flex>
	)
}

export default memo(ActionsBar)
