import { Box, Button, Container, Divider, Flex } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AddIcon } from "@chakra-ui/icons"

import MagazineCard from "../../../../components/Modules/Warehouse/Warehouses/MagazineCard"
import Animation from "../../../../utils/animations/Routes/AnimatedPage"
import BackForwardButtons from "../../../../components/common/BackForwardButtons"
import ModuleHeading from "../../../../components/common/ModuleHeading"
import StaticSearch from "../../../../components/common/StaticSearch"
import BsSortDown from "../../../../assets/icons/permissions/BsSortDown"
import BsSortUp from "../../../../assets/icons/permissions/BsSortUp"

const Warehouses = () => {
	const { t } = useTranslation("global")
	const navigate = useNavigate()
	const location = useLocation()
	const [isDisabled] = useState(false)
	const [isSortDesc] = useState(false)
	const [searchingTerm, setSearchingTerm] = useState("")
	const magazines = [
		{
			id: 1,
			name: "Magazyn NL",
		},
		{
			id: 2,
			name: "Magazyn PL",
		},
		{
			id: 3,
			name: "Magazyn Test",
		},
		{
			id: 4,
			name: "Magazyn Test1",
		},
		{
			id: 5,
			name: "Magazyn Test2",
		},
		{
			id: 6,
			name: "Magazyn Test3",
		},
	]

	const handleSearching = (array) => {
		console.log(array)
	}

	const handleSorting = () => {
		console.log(1)
	}

	return (
		<Animation>
			<Container w='100vw' h={["76vh", "79vh", "83.5vh", "84vh"]} maxW='100%' p={3}>
				<Box
					display='flex'
					justifyContent='space-between'
					flexWrap={{ base: "wrap", md: "nowrap" }}
					mt={2}
					gap={{ md: 2 }}
				>
					<Box display='inline-flex' alignItems='flex-start' mb={[2, 2, 2, 0]}>
						<Flex alignItems={"center"}>
							<BackForwardButtons />
							<ModuleHeading heading={t("Warehouses.heading")} />
						</Flex>
					</Box>
					<Box
						display='inline-flex'
						w='100%'
						justifyContent={"end"}
						h={["35px", "auto"]}
						alignItems='center'
						gap={2}
					>
						<StaticSearch
							fieldsToFilter={["name"]}
							isDisabled={isDisabled}
							searchText={searchingTerm}
							setSearchText={setSearchingTerm}
							handleSetResults={handleSearching}
							originalArray={magazines}
						/>
						<Button
							isDisabled={isDisabled || !magazines?.length}
							my='auto'
							aria-label='sort-button'
							bgColor='#fff'
							minW='30px'
							p={1}
							boxSize={["30px", "35px", "35px", "40px"]}
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);'
							onClick={handleSorting}
						>
							<Flex justifyContent='center'>
								{isSortDesc ? <BsSortDown boxSize={5} /> : <BsSortUp boxSize={5} />}
							</Flex>
						</Button>
						<Button
							aria-label='Add new warehouse button'
							bgColor='#319795'
							color='#FFF'
							colorScheme='teal'
							minW='30px'
							p={1}
							boxSize={["30px", "35px", "35px", "40px"]}
							boxShadow='0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
							onClick={() => navigate(`${location.pathname}/add`)}
						>
							<AddIcon boxSize={[4, 4, 5]} />
						</Button>
					</Box>
				</Box>
				<Divider borderColor='#FFF' borderWidth={["1px", "2px"]} my={4} />
				<Flex flexWrap={"wrap"} gap={5} m={3}>
					{magazines.map((magazine) => (
						<MagazineCard key={magazine.id} magazine={magazine} />
					))}
				</Flex>
			</Container>
		</Animation>
	)
}

export default Warehouses
